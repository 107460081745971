import React from "react";

function Map() {
  return (
    <>
      <div
        className="map_block row_am"
        data-aos="fade-in"
        data-aos-duration={1500}
      >
        <div className="container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3005.8296599429136!2d29.039672850000002!3d41.1164111!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xac31e7cd2f407ab7%3A0x624ac9cd00828926!2sPFM%20Tennis!5e0!3m2!1str!2str!4v1722286971133!5m2!1str!2str"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </div>
      </div>
    </>
  );
}

export default Map;
