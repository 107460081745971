import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css/autoplay";
import ServiceCard from "../Card/ServiceCard";
import { useTranslation } from "react-i18next";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

function PanelModules() {
  const { t } = useTranslation();

  // Servis verilerini t fonksiyonu ile çeviriyoruz ve her birine id ekliyoruz
  const serviceData = [
    {
      title: t("advancedControlPanel"),
      content: t("advancedControlPanelDescription"),
      sectionId: "control-panel",
      schemaType: "ControlPanel",
    },
    {
      title: t("reservationList"),
      content: t("reservationListDescription"),
      schemaType: "ReservationSystem",
    },
    {
      title: t("clubMatches"),
      content: t("clubMatchesDescription"),
      sectionId: "club-matches",
      schemaType: "SportsEvent",
    },
    {
      title: t("membersModule"),
      content: t("membersModuleDescription"),
      sectionId: "club-members",
      schemaType: "Organization",
    },
    {
      title: t("academyModule"),
      content: t("academyModuleDescription"),
      sectionId: "academy",
      schemaType: "EducationalOrganization",
    },
    {
      title: t("teamModule"),
      content: t("teamModuleDescription"),
      sectionId: "team",
      schemaType: "SportsTeam",
    },
    {
      title: t("financeModule"),
      content: t("financeModuleDescription"),
      sectionId: "finance",
      schemaType: "FinancialService",
    },
    {
      title: t("leagueModule"),
      content: t("leagueModuleDescription"),
      sectionId: "leagues",
      schemaType: "SportsLeague",
    },
    {
      title: t("statisticsModule"),
      content: t("statisticsModuleDescription"),
      sectionId: "statistics",
      schemaType: "DataFeed",
    },
    {
      title: t("settingsModule"),
      content: t("settingsModuleDescription"),
      sectionId: "settings",
      schemaType: "WebApplication",
    },
    // {
    //   title: t("clubs"),
    //   content: t("clubsUsingPfmDescription"),
    //   sectionId: "clients",
    // },
  ];

  // Kartlara tıklandığında ilgili bölüme kaydırma fonksiyonu
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <section
      className="about_us_section"
      itemScope
      itemType="https://schema.org/ItemList"
    >
      <div className="container">
        {/* <div
          className="section_title"
          data-aos="fade-up"
          data-aos-duration={1500}
          itemScope
          itemType="https://schema.org/WebPageElement"
        >
          <span className="title_badge" itemProp="name">
            {t("clubManagementPanel")}
          </span>
          <p itemProp="description">{t("pfmClubPanelDescription")}</p>
        </div> */}
        <div className="components-title">
          <h2 className="text-center" itemProp="headline">
            {t("whatWeOffer")}
          </h2>
        </div>
        <div
          className="about_slider"
          data-aos="fade-in"
          data-aos-duration={1500}
          itemScope
          itemType="https://schema.org/ItemList"
        >
          <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            spaceBetween={30}
            navigation={true}
            // pagination={{ clickable: true }}
            loop={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            breakpoints={{
              576: { slidesPerView: 1 },
              768: { slidesPerView: 2 },
              992: { slidesPerView: 3 },
              1200: { slidesPerView: 4 },
            }}
          >
            {serviceData.map((service, index) => (
              <SwiperSlide key={index}>
                <div
                  itemScope
                  itemType={`https://schema.org/${service.schemaType}`}
                >
                  <ServiceCard
                    title={service.title}
                    content={service.content}
                    onClick={() => scrollToSection(service.sectionId)}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
}

export default PanelModules;
