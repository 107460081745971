import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/whiteLogo.png";
import LanguageSwitcher from "../LanguageSwitcher";
import { useTranslation } from "react-i18next";

function Main() {
  const [headerScroll, setheaderScroll] = useState(false);
  const [mobile, setmobile] = useState();
  const { t } = useTranslation();
  useEffect(() => {
    const fixedHeader = () => {
      if (window.pageYOffset > 150) {
        setheaderScroll(true);
      } else {
        setheaderScroll(false);
      }
    };
    window.addEventListener("scroll", fixedHeader);
  }, []);

  return (
    <>
      <header
        className={headerScroll ? "fixed fix_style" : "fixed header_color"}
      >
        <div className="container">
          <nav className="navbar navbar-expand-lg ">
            <Link className="navbar-brand" to="/">
              <img src={logo} alt="Logo" />
            </Link>
            <button
              className={`navbar-toggler ${mobile && "collapsed"}`}
              onClick={() => setmobile(mobile === true ? false : true)}
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <div
                className={`toggle-wrap ${mobile && "active"}`}
                onClick={() => setmobile(mobile === true ? false : true)}
              >
                <span className="toggle-bar"></span>
              </div>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <a className="nav-link" href="/#about_section">
                    {t("whatIsPFM")}
                  </a>
                </li>

                <li className="nav-item">
                  <a className="nav-link" href="/#how_it_section">
                    {t("howToUse")}
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/#our_client">
                    {t("clubs")}
                  </a>
                </li>
                <li className="nav-item active">
                  <a className="nav-link club-panel-btn" href="/club-panel">
                    <i className="icofont-gear"></i> {t("clubPanel")}
                  </a>
                </li>
                <li className="nav-link">
                  <LanguageSwitcher />
                </li>
              </ul>
            </div>

            {mobile && (
              <>
                <div
                  className={`navbar-collapse collapse ${mobile && "show"}`}
                  id="navbarSupportedContent"
                  style={{}}
                >
                  <ul className="navbar-nav ml-auto">
                    {/* <li className="nav-item active">
                      <a className="nav-link club-panel-btn" href="/club-panel">
                        <i className="icofont-gear"></i> {t("clubPanel")}
                      </a>
                    </li> */}
                    <li className="nav-item">
                      <a className="nav-link" href="/#about_section">
                        {t("whatIsPFM")}
                      </a>
                    </li>

                    <li className="nav-item">
                      <a className="nav-link" href="/#how_it_section">
                        {t("howToUse")}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="/#our_client">
                        {t("users")}
                      </a>
                    </li>
                    <li className="nav-item active">
                      <a className="nav-link club-panel-btn" href="/club-panel">
                        <i className="icofont-gear"></i> {t("clubPanel")}
                      </a>
                    </li>
                    <li className="nav-item">
                      <LanguageSwitcher />
                    </li>
                  </ul>
                </div>
              </>
            )}
          </nav>
        </div>
      </header>
    </>
  );
}

export default Main;
