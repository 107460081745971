import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

// Swiper stilleri
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// Resim importları
import categories from "../../assets/images/club-panel/categories.png";
import lessonPackages from "../../assets/images/club-panel/lessonPackages.png";
import services from "../../assets/images/club-panel/services.png";
import packages from "../../assets/images/club-panel/packages.png";
import dynamicHours from "../../assets/images/club-panel/dynamicHours.png";
import courtClose from "../../assets/images/club-panel/courtClose.png";
import courtsandPrices from "../../assets/images/club-panel/courtsandPrices.png";
import clubPictures from "../../assets/images/club-panel/clubPictures.png";
import clubInfo from "../../assets/images/club-panel/clubInfo.png";
import { useTranslation } from "react-i18next";

const ImageGallery = () => {
  const { t } = useTranslation();
  const settingsImages = [
    { img: categories, title: t("categoryDefinition") },
    { img: lessonPackages, title: t("lessonPackages") },
    { img: services, title: t("clubServices") },
    { img: packages, title: t("membershipPackages") },
    { img: dynamicHours, title: t("dynamicHours") },
    { img: courtClose, title: t("courtClosure") },
    { img: courtsandPrices, title: t("courtsAndPrices") },
    { img: clubPictures, title: t("clubPictures") },
    { img: clubInfo, title: t("clubInformation") },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openModal = (index) => {
    setCurrentImageIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const prevImage = () => {
    setCurrentImageIndex((prev) =>
      prev === 0 ? settingsImages.length - 1 : prev - 1
    );
  };

  const nextImage = () => {
    setCurrentImageIndex((prev) =>
      prev === settingsImages.length - 1 ? 0 : prev + 1
    );
  };

  return (
    <div className="image-gallery-container mt-5">
      <h3 className="text-center mb-4">{t("settingsPages")}</h3>

      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={20}
        slidesPerView={1}
        navigation
        loop={true}
        // pagination={{ clickable: true }}
        autoplay={{ delay: 3000, disableOnInteraction: true }}
        breakpoints={{
          576: { slidesPerView: 1 },
          768: { slidesPerView: 2 },
          992: { slidesPerView: 3 },
          1200: { slidesPerView: 4 },
        }}
        className="image-swiper"
      >
        {settingsImages.map((item, index) => (
          <SwiperSlide key={index}>
            <div className="image-card" onClick={() => openModal(index)}>
              <div className="image-container">
                <img
                  src={item.img}
                  alt={item.title}
                  className="gallery-image"
                />
              </div>
              <div className="image-title">
                <h5>{item.title}</h5>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      {/* {isModalOpen && (
        <div className="image-modal">
          <div className="modal-overlay" onClick={closeModal}></div>
          <div className="modal-content">
            <button className="close-button" onClick={closeModal}>
              ×
            </button>

            <div className="modal-image-container">
              <img
                src={settingsImages[currentImageIndex].img}
                alt={settingsImages[currentImageIndex].title}
                className="modal-image"
              />
            </div>

            <h4 className="modal-title">
              {settingsImages[currentImageIndex].title}
            </h4>

            <div className="modal-navigation">
              <button className="nav-button prev" onClick={prevImage}>
                &#10094;
              </button>
              <button className="nav-button next" onClick={nextImage}>
                &#10095;
              </button>
            </div>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default ImageGallery;
