import React from "react";
import {
  FaPhone,
  FaEnvelope,
  FaGlobe,
  FaInstagram,
  FaMapMarkerAlt,
} from "react-icons/fa";
import { useTranslation } from "react-i18next";

const Contact = ({ club }) => {
  const { t, i18n } = useTranslation();
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  // Google Maps için dil kodunu
  const getLanguageCode = () => {
    const currentLanguage = i18n.language;
    return currentLanguage;
  };

  const getClubName = () => {
    const currentLanguage = i18n.language;

    if (currentLanguage === "en" && club.clubNameen) {
      return club.clubNameen;
    } else if (currentLanguage === "ru" && club.clubNameru) {
      return club.clubNameru;
    } else {
      return club.clubNametr || "";
    }
  };

  return (
    <section
      id="club-contact"
      className="club-contact"
      itemScope
      itemType="https://schema.org/SportsActivityLocation"
    >
      <div className="container">
        <div className="club-contact__content">
          <div className="club-contact__info">
            <h2 className="club-contact__title" itemProp="name">
              {t("contactInfo")}
            </h2>

            <div
              className="club-contact__details"
              itemProp="contactPoint"
              itemScope
              itemType="https://schema.org/ContactPoint"
            >
              <div className="club-contact__item">
                <FaMapMarkerAlt className="club-contact__icon" />
                <div
                  className="club-contact__text"
                  itemProp="address"
                  itemScope
                  itemType="https://schema.org/PostalAddress"
                >
                  <h3>{t("address")}</h3>
                  <p itemProp="streetAddress">{getClubName()}</p>
                  <p itemProp="addressLocality">{club.clubCity}</p>
                  <a
                    href={`https://www.google.com/maps/search/?api=1&query=${
                      club.clubLat
                    },${club.clubLong}&hl=${getLanguageCode()}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="club-contact__link"
                    itemProp="hasMap"
                  >
                    {t("getDirections")}
                  </a>
                </div>
              </div>

              {club.clubPhone && (
                <div className="club-contact__item">
                  <FaPhone className="club-contact__icon" />
                  <div className="club-contact__text">
                    <h3>{t("phone")}</h3>
                    <a
                      href={`tel:${club.clubPhone.replace(/\s+/g, "")}`}
                      className="club-contact__link"
                      itemProp="telephone"
                    >
                      {club.clubPhone}
                    </a>
                  </div>
                </div>
              )}

              {club.clubEMail && (
                <div className="club-contact__item">
                  <FaEnvelope className="club-contact__icon" />
                  <div className="club-contact__text">
                    <h3>{t("email")}</h3>
                    <a
                      href={`mailto:${club.clubEMail}`}
                      className="club-contact__link"
                      itemProp="email"
                    >
                      {club.clubEMail}
                    </a>
                  </div>
                </div>
              )}

              {club.clubWebSite && (
                <div className="club-contact__item">
                  <FaGlobe className="club-contact__icon" />
                  <div className="club-contact__text">
                    <h3>{t("website")}</h3>
                    <a
                      href={
                        club.clubWebSite.startsWith("http")
                          ? club.clubWebSite
                          : `https://${club.clubWebSite}`
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      className="club-contact__link"
                      itemProp="url"
                    >
                      {club.clubWebSite}
                    </a>
                  </div>
                </div>
              )}

              {club.clubInstagram && (
                <div className="club-contact__item">
                  <FaInstagram className="club-contact__icon" />
                  <div className="club-contact__text">
                    <h3>{t("instagram")}</h3>
                    <a
                      href={`https://instagram.com/${club.clubInstagram}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="club-contact__link"
                      itemProp="sameAs"
                    >
                      @{club.clubInstagram}
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="club-contact__map">
            <iframe
              src={`https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${
                club.clubLat
              },${club.clubLong}&language=${getLanguageCode()}`}
              width="100%"
              height="100%"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title={`${getClubName()} ${t("location")}`}
              itemProp="hasMap"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
