import React, { useEffect } from "react";
import Header from "../../Components/Header/Main";
import Banner from "../../Components/Home/Banner";
import Benefits from "../../Components/Home/Benefits";
import Aboutus from "../../Components/Home/Aboutus";
import TextList from "../../Components/Home/TextList";
import Service from "../../Components/Home/Service";
import HowWork from "../../Components/Home/HowWork";
import Review from "../../Components/Home/Review";
import Clients from "../../Components/Home/Clients";
//import Pricing from "../../Components/Home/Pricing";
import Interface from "../../Components/Home/Interface";
import Text from "../../Components/Home/Text";
//import Blog from "../../Components/Home/Blog";
import Footer from "../../Components/Footer/Main";
import SEO from "../../Components/SEO";
import Aos from "aos";
import ScrollToTop from "../../Components/Common/ScrollToTop";

function Main() {
  useEffect(() => {
    Aos.init();
    Aos.refresh();
  }, []);

  const homeSchema = {
    "@context": "https://schema.org",
    "@type": ["MobileApplication", "SoftwareApplication"],
    name: {
      tr: "PFM - Tenis Kulüpleri ve Oyuncular İçin Tek Platform",
      en: "PFM - Single Platform for Tennis Clubs and Players",
      ru: "PFM - Единая платформа для теннисных клубов и игроков",
    },
    description: {
      tr: "PFM ile tenis kulüplerini yönetin, kort rezervasyonu yapın, tenis dersleri alın. Hem kulüp yöneticileri hem de tenis severler için kapsamlı çözümler.",
      en: "Manage tennis clubs, make court reservations, take tennis lessons with PFM. Comprehensive solutions for both club managers and tennis enthusiasts.",
      ru: "Управляйте теннисными клубами, бронируйте корты, берите уроки тенниса с PFM. Комплексные решения для менеджеров клубов и любителей тенниса.",
    },
    applicationCategory: ["SportsApplication", "BusinessApplication"],
    operatingSystem: "Android, iOS, Web",
    offers: {
      "@type": "Offer",
      price: "0",
      priceCurrency: "TRY",
    },
    featureList: {
      tr: {
        mobile: [
          "Tenis kortu rezervasyonu",
          "Tenis dersleri",
          "Maç isteği gönderme",
          "Antrenör bulma",
          "Grup dersleri",
          "PFM kredisi ile ödeme",
        ],
        club: [
          "Kolay kort yönetimi",
          "Üye takibi",
          "Rezervasyon sistemi",
          "Mali raporlar",
          "Personel yönetimi",
        ],
      },
      en: {
        mobile: [
          "Tennis court reservation",
          "Tennis lessons",
          "Match request",
          "Find coach",
          "Group lessons",
          "Payment with PFM credits",
        ],
        club: [
          "Easy court management",
          "Member tracking",
          "Reservation system",
          "Financial reports",
          "Staff management",
        ],
      },
      ru: {
        mobile: [
          "Бронирование теннисного корта",
          "Уроки тенниса",
          "Запрос на матч",
          "Поиск тренера",
          "Групповые уроки",
          "Оплата кредитами PFM",
        ],
        club: [
          "Управление кортами",
          "Отслеживание участников",
          "Система бронирования",
          "Финансовые отчеты",
          "Управление персоналом",
        ],
      },
    },
  };

  return (
    <>
      <SEO
        title={{
          tr: "PFM - Tenis, Padel ve Raket Sporları Kulüpleri İçin Yönetim Sistemi Yazılımı | Kort Rezervasyonu ve Kulüp Yönetimi",
          en: "PFM - Management System Software for Tennis, Padel and Racket Sports Clubs | Court Reservation and Club Management",
          ru: "PFM - Программное обеспечение системы управления для клубов тенниса, падела и ракеточных видов спорта | Бронирование кортов и управление клубом",
        }}
        description={{
          tr: "PFM ile tenis, padel ve raket sporları kulüplerini yönetin, kort rezervasyonu yapın. Hem kulüp yöneticileri hem de oyuncular için kapsamlı çözümler. Kolay kort yönetimi, üye takibi, rezervasyon sistemi, tenis ligleri ve challenge'lar.",
          en: "Manage tennis, padel and racket sports clubs, make court reservations with PFM. Comprehensive solutions for both club managers and players. Easy court management, member tracking, reservation system, tennis leagues and challenges.",
          ru: "Управляйте клубами тенниса, падела и ракеточных видов спорта, бронируйте корты с PFM. Комплексные решения для менеджеров клубов и игроков. Управление кортами, отслеживание участников, система бронирования, теннисные лиги и испытания.",
        }}
        keywords={{
          tr: "PFM, PFM App, PFM Kulüp Yönetim Sistemi, PFM Yöneticisi, Play for more, Playformore, Tenis Kulüp Yönetim Sistemi, Padel Kulüp Yönetim Sistemi, Raket Sporları Yönetim Sistemi, Kulüp Yönetim Sistemi, Tenis Kulüp Yönetim Yazılımı, Padel Kulüp Yönetim Yazılımı, Raket Sporları Yönetim Yazılımı, Kulüp Yönetim Yazılımı, Tenis Kulübü, Padel Kulübü, Raket Sporları Kulübü, Raket Sporları, Kort Rezervasyon, Tenis Oyna, Padel Oyna, Pickleball Oyna, Tenis Challenge, Padel Challenge, Pickleball Challenge, Tenis Ligi, Padel Ligi, Pickleball Ligi, Tenis Akademi, Tenis Kortu, Padel Kortu, Pickleball Kortu, Tenis Oyuncu, Padel Oyuncu, Pickleball Oyuncu",
          en: "PFM, PFM App, PFM Club Management System, PFM Manager, Play for more, Playformore, Tennis Club Management System, Padel Club Management System, Racket Sports Management System, Club Management System, Tennis Club Management Software, Padel Club Management Software, Racket Sports Management Software, Club Management Software, Tennis Club, Padel Club, Racket Sports Club, Racket Sports, Court Reservation, Play Tennis, Play Padel, Play Pickleball, Tennis Challenge, Padel Challenge, Pickleball Challenge, Tennis League, Padel League, Pickleball League, Tennis Academy, Tennis Court, Padel Court, Pickleball Court, Tennis Player, Padel Player, Pickleball Player",
          ru: "PFM, PFM приложение, Система управления клубом PFM, Менеджер PFM, Play for more, Playformore, Система управления теннисным клубом, Система управления клубом падел, Система управления клубом ракеточных видов спорта, Система управления клубом, Программное обеспечение для управления теннисным клубом, Программное обеспечение для управления клубом падел, Программное обеспечение для управления клубом ракеточных видов спорта, Программное обеспечение для управления клубом, Теннисный клуб, Клуб падел, Клуб ракеточных видов спорта, Ракеточные виды спорта, Бронирование корта, Играть в теннис, Играть в падел, Играть в пиклбол, Теннисный челлендж, Падел челлендж, Пиклбол челлендж, Теннисная лига, Лига падел, Лига пиклбол, Теннисная академия, Теннисный корт, Корт для падел, Корт для пиклбол, Теннисист, Игрок в падел, Игрок в пиклбол",
        }}
        ogTitle={{
          tr: "PFM - Tenis, Padel ve Raket Sporları Kulüpleri İçin Yönetim Sistemi Yazılımı",
          en: "PFM - Management System Software for Tennis, Padel and Racket Sports Clubs",
          ru: "PFM - Программное обеспечение системы управления для клубов тенниса, падела и ракеточных видов спорта",
        }}
        ogDescription={{
          tr: "PFM ile tenis, padel ve raket sporları kulüplerini yönetin, kort rezervasyonu yapın. Hem kulüp yöneticileri hem de oyuncular için kapsamlı çözümler.",
          en: "Manage tennis, padel and racket sports clubs, make court reservations with PFM. Comprehensive solutions for both club managers and players.",
          ru: "Управляйте клубами тенниса, падела и ракеточных видов спорта, бронируйте корты с PFM. Комплексные решения для менеджеров клубов и игроков.",
        }}
        ogImage="https://www.playformore.app/assets/images/logo.png"
        ogUrl="https://www.playformore.app"
        twitterTitle={{
          tr: "PFM - Tenis Kulüpleri ve Oyuncular İçin Tek Platform",
          en: "PFM - Single Platform for Tennis Clubs and Players",
          ru: "PFM - Единая платформа для теннисных клубов и игроков",
        }}
        twitterDescription={{
          tr: "PFM ile tenis kulüplerini yönetin, kort rezervasyonu yapın, tenis dersleri alın. Hem kulüp yöneticileri hem de tenis severler için kapsamlı çözümler.",
          en: "Manage tennis clubs, make court reservations, take tennis lessons with PFM. Comprehensive solutions for both club managers and tennis enthusiasts.",
          ru: "Управляйте теннисными клубами, бронируйте корты, берите уроки тенниса с PFM. Комплексные решения для менеджеров клубов и любителей тенниса.",
        }}
        twitterImage="https://www.playformore.app/assets/images/logo.png"
        schema={homeSchema}
      />
      <Header />
      <Banner />
      <Benefits />
      <div className="page_wrapper">
        <Aboutus />
        <TextList />
        <Service />
        <HowWork />
      </div>
      {/*  <Review />*/}
      <div className="page_wrapper">
        <Clients />
        {/*<Pricing />*/}
        {/* <Interface /> */}
        <Text />
        {/*<Blog />*/}
        <Footer />
      </div>
      <ScrollToTop />
    </>
  );
}

export default Main;
