import React, { useState, useEffect, useRef } from "react";
import {
  FaInfoCircle,
  FaUsers,
  FaTrophy,
  FaStar,
  FaMapMarkerAlt,
  FaPhone,
  FaEnvelope,
  FaGlobe,
  FaInstagram,
  FaChevronDown,
  FaChevronUp,
} from "react-icons/fa";
import { useTranslation } from "react-i18next";

const Info = ({ club }) => {
  const { t, i18n } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [showExpandButton, setShowExpandButton] = useState(false);
  const aboutContentRef = useRef(null);
  const contactCardRef = useRef(null);
  const [aboutHeight, setAboutHeight] = useState("auto");
  const [initialCardHeight, setInitialCardHeight] = useState("auto");

  const getClubDescription = () => {
    const currentLanguage = i18n.language;

    if (currentLanguage === "en" && club.clubDescen) {
      return <div dangerouslySetInnerHTML={{ __html: club.clubDescen }} />;
    } else if (currentLanguage === "ru" && club.clubDescru) {
      return <div dangerouslySetInnerHTML={{ __html: club.clubDescru }} />;
    } else {
      return <div dangerouslySetInnerHTML={{ __html: club.clubDesctr }} />;
    }
  };

  const extractCourtInfo = () => {
    const desc = club.clubDesctr || "";
    const courtMatch = desc.match(/(\d+)\s+adet\s+(\w+)\s+kort/g);

    if (!courtMatch) return [];

    return courtMatch.map((match) => {
      const parts = match.match(/(\d+)\s+adet\s+(\w+)\s+kort/);
      if (parts && parts.length >= 3) {
        const [_, count, type] = parts;
        return `${count} adet ${type} kort`;
      }
      return match;
    });
  };

  useEffect(() => {
    const checkContentHeight = () => {
      if (aboutContentRef.current && contactCardRef.current) {
        const contentHeight = aboutContentRef.current.scrollHeight;
        const contactCardHeight = contactCardRef.current.clientHeight - 60;
        const maxHeight = 300;
        setInitialCardHeight(`${contactCardHeight}px`);

        setShowExpandButton(
          contentHeight > Math.min(maxHeight, contactCardHeight)
        );

        if (!expanded) {
          setAboutHeight(`${Math.min(maxHeight, contactCardHeight)}px`);
        } else {
          setAboutHeight(`${contentHeight}px`);
        }
      }
    };

    const timer = setTimeout(() => {
      checkContentHeight();
    }, 100);

    window.addEventListener("resize", checkContentHeight);

    return () => {
      clearTimeout(timer);
      window.removeEventListener("resize", checkContentHeight);
    };
  }, [expanded, club]);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const courtFeatures = extractCourtInfo();

  return (
    <section
      id="club-info"
      className="club-info"
      itemScope
      itemType="https://schema.org/SportsActivityLocation"
    >
      <div className="container">
        <div className="club-info__wrapper">
          <div className="club-info__main">
            <div
              className={`club-info__section club-info__about ${
                expanded ? "expanded" : ""
              }`}
              itemProp="description"
            >
              <h2 className="club-info__title">
                <FaInfoCircle className="club-info__icon" />
                {t("aboutClub")}
              </h2>
              <div
                className="club-info__description"
                ref={aboutContentRef}
                style={{
                  maxHeight: aboutHeight,
                  overflow: showExpandButton ? "hidden" : "visible",
                  transition: "max-height 0.5s ease",
                }}
              >
                {getClubDescription()}
              </div>
              {showExpandButton && (
                <button
                  className="club-info__expand-button"
                  onClick={toggleExpand}
                >
                  {expanded ? (
                    <>
                      <FaChevronUp className="club-info__expand-icon" />
                      {t("readLess") || "Daha az göster"}
                    </>
                  ) : (
                    <>
                      <FaChevronDown className="club-info__expand-icon" />
                      {t("readMore") || "Devamını oku"}
                    </>
                  )}
                </button>
              )}
            </div>

            {/* {courtFeatures.length > 0 && (
              <div
                className="club-info__section club-info__features"
                itemProp="amenityFeature"
                itemScope
                itemType="https://schema.org/LocationFeatureSpecification"
              >
                <h2 className="club-info__title">
                  <FaStar className="club-info__icon" />
                  {t("highlightedFeatures")}
                </h2>
                <ul className="club-info__features-list">
                  {courtFeatures.map((feature, index) => (
                    <li
                      key={`court-${index}`}
                      className="club-info__feature-item"
                      itemProp="value"
                    >
                      <FaTrophy className="club-info__feature-icon" />
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
            )} */}
          </div>

          <div
            className="club-info__section club-info__contact"
            itemProp="contactPoint"
            itemScope
            itemType="https://schema.org/ContactPoint"
            ref={contactCardRef}
          >
            <h2 className="club-info__title">
              <FaUsers className="club-info__icon" />
              {t("contactInformation")}
            </h2>
            <div className="club-info__contact-details">
              <div
                className="club-info__contact-item"
                itemProp="address"
                itemScope
                itemType="https://schema.org/PostalAddress"
              >
                <FaMapMarkerAlt className="club-info__contact-icon" />
                <div className="club-info__contact-text">
                  <strong>{t("address")}:</strong>
                  <span itemProp="addressLocality">{club.clubCity}</span>
                </div>
              </div>

              <div className="club-info__contact-item">
                <FaPhone className="club-info__contact-icon" />
                <div className="club-info__contact-text">
                  <strong>{t("phone")}:</strong>
                  <a
                    href={`tel:${club.clubPhone}`}
                    itemProp="telephone"
                    className="club-info__link"
                  >
                    {club.clubPhone}
                  </a>
                </div>
              </div>

              <div className="club-info__contact-item">
                <FaEnvelope className="club-info__contact-icon" />
                <div className="club-info__contact-text">
                  <strong>{t("email")}:</strong>
                  <a
                    href={`mailto:${club.clubEMail}`}
                    itemProp="email"
                    className="club-info__link"
                  >
                    {club.clubEMail}
                  </a>
                </div>
              </div>

              {club.clubWebSite && (
                <div className="club-info__contact-item">
                  <FaGlobe className="club-info__contact-icon" />
                  <div className="club-info__contact-text">
                    <strong>{t("website")}:</strong>
                    <a
                      href={club.clubWebSite}
                      target="_blank"
                      rel="noopener noreferrer"
                      itemProp="url"
                      className="club-info__link"
                    >
                      {club.clubWebSite.replace(/^https?:\/\//, "")}
                    </a>
                  </div>
                </div>
              )}

              {club.clubInstagram && (
                <div className="club-info__contact-item">
                  <FaInstagram className="club-info__contact-icon" />
                  <div className="club-info__contact-text">
                    <strong>Instagram:</strong>
                    <a
                      href={`https://instagram.com/${club.clubInstagram}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      itemProp="sameAs"
                      className="club-info__link"
                    >
                      @{club.clubInstagram}
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Info;
