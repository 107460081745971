import React from "react";
import clubSettings from "../../assets/images/club-panel/clubSettings.png";
import ImageGallery from "./ImageGallery";
import { useTranslation } from "react-i18next";

function Settings({ id }) {
  const { t } = useTranslation();
  const settingOperations = [
    t("clubMembershipOptions"),
    t("clubWorkingHoursAndQuotas"),
    t("paymentPreferences"),
    t("clubInfoAndImages"),
    t("courtOperations"),
    t("dynamicHoursAndPricing"),
    t("memberCategoryDefinition"),
    t("membershipAndLessonPackages"),
    t("clubServicesDefinition"),
  ];

  return (
    <section className="row_am service_section about_service" id={id}>
      <div className="container">
        <div
          className="section_title"
          data-aos="fade-up"
          data-aos-duration={1500}
        >
          <h2>{t("generalSettings")}</h2>
          <p>{t("generalSettingsDescription")}</p>
        </div>

        <div className="league-features-container mt-5">
          <div className="row align-items-center">
            <div
              className="col-lg-6 col-md-12"
              data-aos="fade-left"
              data-aos-duration={1200}
            >
              <div className="service_text">
                <h4 className="feature-title mb-4">
                  <span>{t("settingsAndDefinitions")}</span>
                </h4>

                <ul className="feature_list">
                  {settingOperations.map((feature, index) => (
                    <div
                      key={index}
                      className="feature-item-minimal animated-item"
                      style={{ animationDelay: `${index * 500}ms` }}
                    >
                      <div className="feature-icon-minimal">
                        <svg
                          className="checkmark-icon"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="checkmark-circle"
                            cx="12"
                            cy="12"
                            r="11"
                            fill="#f0f7ff"
                          />
                          <path
                            className="checkmark-check"
                            d="M7 13l3 3 7-7"
                            stroke="#4169e1"
                            strokeWidth="2"
                            fill="none"
                          />
                        </svg>
                      </div>
                      <div className="feature-text-minimal">{feature}</div>
                    </div>
         
                  ))}
                </ul>
              </div>
            </div>
            <div
              className="col-lg-6 col-md-12 mb-4 mb-lg-0"
              data-aos="fade-right"
              data-aos-duration={1200}
            >
              <div className="league-image-wrapper">
                <img
                  src={clubSettings}
                  alt="Lig ve Turnuva Modülü"
                  className="img-fluid rounded shadow-sm league-image"
                />
              </div>
            </div>
          </div>
        </div>
        <ImageGallery />
      </div>
    </section>
  );
}

export default Settings;
