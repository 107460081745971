import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/whiteLogo.png";
import { useTranslation } from "react-i18next";
import { FaSignInAlt } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import LanguageSwitcher from "../LanguageSwitcher";

function ClubPanelHeader() {
  const [headerScroll, setheaderScroll] = useState(false);
  const [mobile, setmobile] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    const fixedHeader = () => {
      if (window.pageYOffset > 150) {
        setheaderScroll(true);
      } else {
        setheaderScroll(false);
      }
    };
    window.addEventListener("scroll", fixedHeader);
  }, []);

  return (
    <>
      <header
        className={`club-panel-header ${
          headerScroll ? "fixed fix_style" : "fixed header_color"
        }`}
      >
        <div className="container">
          <nav className="navbar navbar-expand-lg ">
            <Link className="navbar-brand" to="/">
              <img src={logo} alt="Logo" />
            </Link>
            <button
              className={`navbar-toggler ${mobile && "collapsed"}`}
              onClick={() => setmobile(mobile === true ? false : true)}
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <div
                className={`toggle-wrap ${mobile && "active"}`}
                onClick={() => setmobile(mobile === true ? false : true)}
              >
                <span className="toggle-bar"></span>
              </div>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mr-auto">
                <li className="nav-item">
                  <a className="nav-link" href="/">
                    PFM App
                  </a>
                </li>
              </ul>
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <a
                    className="nav-link get-started-icon-btn"
                    href="https://manager.playformore.app/"
                    target="_blank"
                    rel="noopener noreferrer"
                    title={t("getStarted")}
                  >
                    <FaArrowRight />
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link panel-login-btn"
                    href="https://manager.playformore.app/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaSignInAlt style={{ marginRight: "8px" }} />
                    {t("panelLogin")}
                  </a>
                </li>
                <LanguageSwitcher />
              </ul>
            </div>

            {mobile && (
              <>
                <div
                  className={`navbar-collapse collapse ${mobile && "show"}`}
                  id="navbarSupportedContent"
                  style={{}}
                >
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <a className="nav-link" href="/">
                        PFM App
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link get-started-icon-btn"
                        href="https://manager.playformore.app/"
                        target="_blank"
                        rel="noopener noreferrer"
                        title={t("getStarted")}
                      >
                        <FaArrowRight />
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link panel-login-btn"
                        href="https://app.playformore.app/panel/login"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaSignInAlt style={{ marginRight: "8px" }} />
                        {t("panelLogin")}
                      </a>
                    </li>
                    <li className="nav-link">
                      <LanguageSwitcher />
                    </li>
                  </ul>
                </div>
              </>
            )}
          </nav>
        </div>
      </header>
    </>
  );
}

export default ClubPanelHeader;
