import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

// Swiper stilleri
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import leaguesList from "../../assets/images/club-panel/leaguesList.png";
import leagueMatches from "../../assets/images/club-panel/leagueMatches.png";
import leagueParticipants from "../../assets/images/club-panel/leagueParticipants.png";
import leagueEnterscore from "../../assets/images/club-panel/leaguesEnterscore.png";
import leagueScoreboard from "../../assets/images/club-panel/leagueScoreboard.png";
import { useTranslation } from "react-i18next";

function Leagues({ id }) {
  const { t } = useTranslation();
  const leagueSpecs = [
    t("createLeagueWithCustomFormat"),
    t("manageParticipantsViaPfmApp"),
    t("autoCreateGroupAndFixture"),
    t("planMatchDatesAndTimes"),
    t("enterScoresAndUpdateTable"),
    t("offerVariousFormats"),
    t("followTournamentResultsViaMobile"),
  ];
  const leaguePages = [
    { img: leagueEnterscore, title: t("enterMatchResult") },
    { img: leagueMatches, title: t("leagueMatches") },
    { img: leagueParticipants, title: t("leagueParticipants") },
    { img: leagueScoreboard, title: t("scoreBoard") },
  ];
  return (
    <section className="row_am service_section about_service" id={id}>
      <div className="container">
        <div
          className="section_title"
          data-aos="fade-up"
          data-aos-duration={1500}
        >
          <h2>{t("leagueAndTournamentModule")}</h2>
          <p>{t("leagueModuleDescription2")}</p>
        </div>

        <div className="league-features-container mt-5">
          <div className="row align-items-center">
            {/* Sol taraf - Resim */}
            <div
              className="col-lg-6 col-md-12 mb-4 mb-lg-0"
              data-aos="fade-right"
              data-aos-duration={1200}
            >
              <div className="league-image-wrapper">
                <img
                  src={leaguesList}
                  alt="Lig ve Turnuva Modülü"
                  className="img-fluid rounded shadow-sm league-image"
                />
              </div>
            </div>

            {/* Sağ taraf - Özellikler */}
            <div
              className="col-lg-6 col-md-12"
              data-aos="fade-left"
              data-aos-duration={1200}
            >
              <div className="service_text">
                <h4 className="feature-title mb-4">
                  <span>{t("leagueModuleOperations")}</span>
                </h4>
                <ul className="feature_list">
                  {leagueSpecs.map((feature, index) => (
                    <div
                      key={index}
                      className="feature-item-minimal animated-item"
                      style={{ animationDelay: `${index * 500}ms` }}
                    >
                      <div className="feature-icon-minimal">
                        <svg
                          className="checkmark-icon"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="checkmark-circle"
                            cx="12"
                            cy="12"
                            r="11"
                            fill="#f0f7ff"
                          />
                          <path
                            className="checkmark-check"
                            d="M7 13l3 3 7-7"
                            stroke="#4169e1"
                            strokeWidth="2"
                            fill="none"
                          />
                        </svg>
                      </div>
                      <div className="feature-text-minimal">{feature}</div>
                    </div>
                    // <li key={index}>
                    //   <div className="icon">
                    //     <span>
                    //       <i className="icofont-check" />
                    //     </span>
                    //   </div>
                    //   <div className="text">
                    //     <p>{feature}</p>
                    //   </div>
                    // </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div
          className="payment-types-slider mt-5"
          data-aos="fade-up"
          data-aos-duration={1500}
        >
          <div className="container">
            <h3 className="text-center mb-4">
              {t("leagueAndTournamentPages")}
            </h3>
            <Swiper
              modules={[Navigation, Pagination, Autoplay]}
              spaceBetween={30}
              slidesPerView={3}
              centeredSlides={true}
              loop={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              pagination={{ clickable: true }}
              // navigation={true}
              effect={"coverflow"}
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 100,
                modifier: 2,
                slideShadows: true,
              }}
              className="swiper-3d"
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },

                768: {
                  slidesPerView: 2,
                  spaceBetween: 30,
                },

                1024: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }}
            >
              {leaguePages.map((item, index) => (
                <SwiperSlide key={index}>
                  <div className="swiper-card">
                    <div className="swiper-card-image">
                      <img
                        src={item.img}
                        alt={item.title}
                        className="img-fluid"
                      />
                    </div>
                    <div className="swiper-card-title">
                      <h5>{item.title}</h5>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Leagues;
