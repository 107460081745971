import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ClubDetailHeader from "../../Components/Header/ClubDetailHeader";
import ClubDetailFooter from "../../Components/Footer/ClubDetailFooter";
import ClubSEO from "../../Components/ClubSEO";
import Hero from "../../Components/Clubs/Detail/Hero";
import Info from "../../Components/Clubs/Detail/Info";
// import Courts from "../../Components/Clubs/Detail/Courts";
// import Academy from "../../Components/Clubs/Detail/Academy";
import Contact from "../../Components/Clubs/Detail/Contact";
import Map from "../../Components/Clubs/Detail/Map";
import Gallery from "../../Components/Clubs/Detail/Gallery";
import Reviews from "../../Components/Clubs/Detail/Reviews";
// import "../../styles/clubs/detail.css";
import ScrollToTop from "../../Components/Common/ScrollToTop";

const ClubDetail = () => {
  const { t } = useTranslation();
  const { urlSlug } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [club, setClub] = useState(null);
  console.log(useParams());
  console.log(urlSlug);
  useEffect(() => {
    const fetchClubDetails = async () => {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("clubUrlSlug", urlSlug);

        const response = await fetch(
          "https://club.pfmtennis.com/api/clubs/getClubPublicInfo",
          {
            method: "POST",
            body: formData,
          }
        );

        if (!response.ok) {
          throw new Error("Kulüp bilgileri alınamadı");
        }

        const data = await response.json();

        if (
          data.result === "success" &&
          data.clubInfo &&
          data.clubInfo.length > 0
        ) {
          setClub(data.clubInfo[0]);
        } else {
          setError("Kulüp bulunamadı");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (urlSlug) {
      fetchClubDetails();
    } else {
      setError("Kulüp bulunamadı");
      setLoading(false);
    }
  }, [urlSlug]);

  if (loading) {
    return (
      <>
        <ClubDetailHeader />
        <div className="loading">
          <div className="spinner"></div>
          <p>{t("clubDetailsLoading")}</p>
        </div>
      </>
    );
  }

  if (error) {
    return (
      <>
        <ClubDetailHeader />
        <div className="error">
          <h2>{t("error")}</h2>
          <p>{error}</p>
        </div>
      </>
    );
  }

  if (!club) {
    return (
      <>
        <ClubDetailHeader />
        <div className="not_found">
          <h2>{t("clubNotFound")}</h2>
          <p>{t("clubNotFoundDescription")}</p>
        </div>
      </>
    );
  }

  return (
    <>
      <ClubSEO club={club} />
      <ClubDetailHeader club={club} />
      <ScrollToTop isClubDetail={true} />
      <div className="page_wrapper">
        <Hero club={club} />
        <div id="club-about">
          <Info club={club} />
        </div>
        {/* {club.clubCourts && club.clubCourts.length > 0 && (
          <div id="club-courts">
            <Courts club={club} />
          </div>
        )} */}
        {/* {club.clubAcademies && club.clubAcademies.length > 0 && (
          <Academy club={club} />
        )} */}
        {club.clubPhotos && club.clubPhotos.length > 0 && (
          <div id="club-gallery">
            <Gallery club={club} />
          </div>
        )}
        <div id="club-contact">
          <Contact club={club} />
        </div>
        {/* <div id="club-map">
          <Map club={club} />
        </div> */}
        {/* <div id="club-reviews">
          <Reviews club={club} />
        </div> */}
        <ClubDetailFooter club={club} />
      </div>
    </>
  );
};

export default ClubDetail;
