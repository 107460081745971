import React from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "react-bootstrap";

function LanguageSwitcher({ isClubDetail }) {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const flags = {
    tr: (
      <svg
        className="flag-icon"
        viewBox="0 0 1200 800"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="1200" height="800" fill="#E30A17" />
        <circle cx="425" cy="400" r="200" fill="#ffffff" />
        <circle cx="475" cy="400" r="160" fill="#E30A17" />
        <polygon
          points="583.334,400 764.235,458.779 652.431,304.894 652.431,495.106 764.235,341.221"
          fill="#ffffff"
        />
      </svg>
    ),
    en: (
      <svg
        className="flag-icon"
        viewBox="0 0 1200 600"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="1200" height="600" fill="#012169" />
        <path
          d="M0,0 L1200,600 M1200,0 L0,600"
          stroke="#fff"
          strokeWidth="120"
        />
        <path
          d="M0,0 L1200,600 M1200,0 L0,600"
          stroke="#C8102E"
          strokeWidth="80"
        />
        <path
          d="M600,0 L600,600 M0,300 L1200,300"
          stroke="#fff"
          strokeWidth="200"
        />
        <path
          d="M600,0 L600,600 M0,300 L1200,300"
          stroke="#C8102E"
          strokeWidth="120"
        />
      </svg>
    ),
    ru: (
      <svg
        className="flag-icon"
        viewBox="0 0 1200 800"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="1200" height="266.67" fill="#fff" />
        <rect width="1200" height="266.67" y="266.67" fill="#0039A6" />
        <rect width="1200" height="266.67" y="533.33" fill="#D52B1E" />
      </svg>
    ),
  };

  return (
    <Dropdown
      className={`language-dropdown ${
        isClubDetail ? "club-detail-language" : ""
      }`}
    >
      <Dropdown.Toggle
        variant="link"
        id="language-dropdown"
        className={`lang-toggle ${ isClubDetail ? "club-detail-toggle" : ""}`}
      >
        {flags[i18n.language] || flags.en}
        <span className="lang-name d-none d-md-inline">
          {i18n.language === "tr" ? "TR" : i18n.language === "ru" ? "RU" : "EN"}
        </span>
      </Dropdown.Toggle>

      <Dropdown.Menu className="lang-dropdown-menu">
        <Dropdown.Item
          onClick={() => changeLanguage("tr")}
          active={i18n.language === "tr"}
          className={`lang-option ${
           isClubDetail ? "club-detail-option" : ""
          }`}
        >
          {flags.tr}
          <span className="lang-text">Türkçe</span>
        </Dropdown.Item>

        <Dropdown.Item
          onClick={() => changeLanguage("en")}
          active={i18n.language === "en"}
          className={`lang-option ${
            isClubDetail ? "club-detail-option" : ""
           }`}
        >
          {flags.en}
          <span className="lang-text">English</span>
        </Dropdown.Item>

        <Dropdown.Item
          onClick={() => changeLanguage("ru")}
          active={i18n.language === "ru"}
          className={`lang-option ${
            isClubDetail ? "club-detail-option" : ""
           }`}
        >
          {flags.ru}
          <span className="lang-text">Русский</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default LanguageSwitcher;
