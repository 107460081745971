import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { Link } from "react-router-dom";

import "swiper/css";

function Clients({ id }) {
  const { t } = useTranslation();
  const [clients, setClients] = useState([]);

  useEffect(() => {
    const getClubData = async () => {
      try {
        const response = await fetch(
          "https://club.pfmtennis.com/api/clubs/getClubLogos"
        );
        const data = await response.json();

        if (data.result === "success" && data.clubs) {
          setClients(data.clubs);
        }
      } catch (error) {
        console.error("Kulüp verileri yüklenirken hata:", error);
      }
    };

    getClubData();
  }, []);

  const adjustedClients =
    clients.length % 2 === 0 ? clients : clients.slice(0, clients.length - 1);

  const firstRow = adjustedClients.filter((_, index) => index % 2 === 0);
  const secondRow = adjustedClients.filter((_, index) => index % 2 === 1);

  const getClubSlug = (clubName) => {
    return clubName.toLowerCase().replace(/\s+/g, "-");
  };

  return (
    <section className="row_am our_client" id={id}>
      <div className="container">
        <div
          className="section_title"
          data-aos="fade-up"
          data-aos-duration={1500}
        >
          <h2>{t("managementPanelClubs")}</h2>
        </div>

        <div className="infinite_carousel_container">
          <div className="carousel_row">
            <Swiper
              modules={[Autoplay]}
              spaceBetween={30}
              slidesPerView="auto"
              loop={true}
              speed={5000}
              autoplay={{
                delay: 0,
                disableOnInteraction: false,
              }}
              allowTouchMove={false}
              simulateTouch={false}
              touchRatio={0}
              grabCursor={false}
              className="infinite_swiper"
            >
              {firstRow.map((club, index) => (
                <SwiperSlide key={`first-${index}`} className="club_slide">
                  <Link
                    to={`/club/${club.urlSlug}`}
                    className="club_card"
                    aria-label={`${club.clubName} tenis kulübü detayları`}
                  >
                    <div className="club_logo">
                      <img
                        src={club.clubLogo}
                        alt={`${club.clubName} tenis kulübü logosu`}
                        loading="lazy"
                        width="150"
                        height="150"
                      />
                    </div>
                    <div className="club_name">
                      <h3>{club.clubName}</h3>
                    </div>
                  </Link>
                </SwiperSlide>
              ))}
              {firstRow.map((club, index) => (
                <SwiperSlide key={`first-copy-${index}`} className="club_slide">
                  <Link
                    to={`/club/${club.urlSlug}`}
                    className="club_card"
                    aria-label={`${club.clubName} tenis kulübü detayları`}
                  >
                    <div className="club_logo">
                      <img
                        src={club.clubLogo}
                        alt={`${club.clubName} tenis kulübü logosu`}
                        loading="lazy"
                        width="150"
                        height="150"
                      />
                    </div>
                    <div className="club_name">
                      <h3>{club.clubName}</h3>
                    </div>
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          <div className="carousel_row">
            <Swiper
              modules={[Autoplay]}
              spaceBetween={30}
              slidesPerView="auto"
              loop={true}
              speed={5000}
              autoplay={{
                delay: 0,
                disableOnInteraction: false,
              }}
              allowTouchMove={false}
              simulateTouch={false}
              touchRatio={0}
              grabCursor={false}
              className="infinite_swiper"
            >
              {secondRow.map((club, index) => (
                <SwiperSlide key={`second-${index}`} className="club_slide">
                  <Link
                    to={`/club/${club.urlSlug}`}
                    className="club_card"
                    aria-label={`${club.clubName} tenis kulübü detayları`}
                  >
                    <div className="club_logo">
                      <img
                        src={club.clubLogo}
                        alt={`${club.clubName} tenis kulübü logosu`}
                        loading="lazy"
                        width="150"
                        height="150"
                      />
                    </div>
                    <div className="club_name">
                      <h3>{club.clubName}</h3>
                    </div>
                  </Link>
                </SwiperSlide>
              ))}
              {secondRow.map((club, index) => (
                <SwiperSlide
                  key={`second-copy-${index}`}
                  className="club_slide"
                >
                  <Link
                    to={`/club/${club.urlSlug}`}
                    className="club_card"
                    aria-label={`${club.clubName} tenis kulübü detayları`}
                  >
                    <div className="club_logo">
                      <img
                        src={club.clubLogo}
                        alt={`${club.clubName} tenis kulübü logosu`}
                        loading="lazy"
                        width="150"
                        height="150"
                      />
                    </div>
                    <div className="club_name">
                      <h3>{club.clubName}</h3>
                    </div>
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Clients;
