import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import pfmPhoneLogo from "../../assets/images/pfmPhoneLogo.png";
import Application from "../../assets/images/applicationvideothumb.png";
import Playbtn from "../../assets/images/play_white.svg";
import Videobtn from "../../assets/images/playvideotext.png";
import Mobileicon from "../../assets/images/mobileicon.png";
import Magneticon from "../../assets/images/magnet.png";
import { useTranslation } from "react-i18next";

function Aboutus() {
  const { t } = useTranslation();
  const [counts, setCounts] = useState({
    members: 0,
    clubs: 0,
    reservations: 0,
    matches: 0,
  });

  useEffect(() => {
    const updateCounter = () => {
      const targetCounts = {
        members: 4500,
        clubs: 16,
        reservations: 9000,
        matches: 4000,
      };

      const duration = 1500;
      const stepSize = 10;
      const steps = duration / stepSize;

      Object.keys(targetCounts).forEach((key) => {
        const targetCount = targetCounts[key];
        const currentCount = counts[key];
        const step = Math.ceil((targetCount - currentCount) / steps);

        if (step !== 0) {
          const interval = setInterval(() => {
            setCounts((prevCounts) => ({
              ...prevCounts,
              [key]: prevCounts[key] + step,
            }));
          }, stepSize);

          setTimeout(() => {
            clearInterval(interval);
            setCounts((prevCounts) => ({
              ...prevCounts,
              [key]: targetCount,
            }));
          }, duration);
        }
      });
    };

    updateCounter();
  }, []);

  const [ytshow, setytShow] = useState(false);

  return (
    <>
      <section
        id="about_section"
        className="about_section row_am"
        itemScope
        itemType="https://schema.org/AboutPage"
      >
        <div className="container">
          <div
            className="section_title"
            data-aos="fade-up"
            data-aos-duration={1500}
          >
            <span className="title_badge mb-1" itemProp="name">
              {t("pfmWhatIs")}
            </span>
            <h1 itemProp="headline">
              <span itemProp="name">PFM</span>{" "}
              <img src={Mobileicon} alt={t("mobileAppIcon")} loading="lazy" />{" "}
              {t("pfmFirstPart")}{" "}
              <span>
                <img
                  src={Magneticon}
                  alt={t("competitionIcon")}
                  loading="lazy"
                />{" "}
                {t("pfmCompetition")}
              </span>{" "}
              {t("pfmLastPart")}
            </h1>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <ul
                className="app_statstic"
                id="counter"
                data-aos="fade-in"
                data-aos-duration={1500}
                itemScope
                itemType="https://schema.org/ItemList"
              >
                <li
                  data-aos="fade-up"
                  data-aos-duration={1500}
                  itemProp="itemListElement"
                  itemScope
                  itemType="https://schema.org/ListItem"
                >
                  <div className="text">
                    <p>
                      <span className="counter-value" itemProp="value">
                        {counts.members}
                      </span>
                      <span>+</span>
                    </p>
                    <p itemProp="name">{t("memberLabel")}</p>
                  </div>
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-duration={1500}
                  itemProp="itemListElement"
                  itemScope
                  itemType="https://schema.org/ListItem"
                >
                  <div className="text">
                    <p>
                      <span className="counter-value" itemProp="value">
                        {counts.clubs}
                      </span>
                    </p>
                    <p itemProp="name">{t("clubLabel")}</p>
                  </div>
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-duration={1500}
                  itemProp="itemListElement"
                  itemScope
                  itemType="https://schema.org/ListItem"
                >
                  <div className="text">
                    <p>
                      <span className="counter-value" itemProp="value">
                        {counts.reservations}
                      </span>
                      <span>+</span>
                    </p>
                    <p itemProp="name">{t("reservationLabel")}</p>
                  </div>
                </li>
                <li
                  data-aos="fade-up"
                  data-aos-duration={1500}
                  itemProp="itemListElement"
                  itemScope
                  itemType="https://schema.org/ListItem"
                >
                  <div className="text">
                    <p>
                      <span className="counter-value" itemProp="value">
                        {counts.matches}
                      </span>
                      <span>+</span>
                    </p>
                    <p itemProp="name">{t("matchLabel")}</p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-lg-4 col-md-6">
              <div
                className="abtImg text-center"
                data-aos="fade-up"
                data-aos-duration={1500}
                itemScope
                itemType="https://schema.org/ImageObject"
              >
                <img
                  src={pfmPhoneLogo}
                  alt={t("pfmAppLogo")}
                  loading="lazy"
                  itemProp="contentUrl"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <p
                data-aos="fade-up"
                data-aos-duration={1500}
                itemProp="description"
              >
                {t("pfmDetailedDescription")}
              </p>
              <div
                className="video_block"
                data-aos="fade-up"
                data-aos-duration={1500}
                itemScope
                itemType="https://schema.org/VideoObject"
              >
                <img
                  className="thumbnil"
                  src={Application}
                  alt={t("pfmVideoThumbnail")}
                  loading="lazy"
                  itemProp="thumbnail"
                />
                <div className="playBtn" onClick={() => setytShow(true)}>
                  <Link
                    to="#"
                    className="popup-youtube play-button play_icon"
                    data-url="https://youtube.com/embed/bXqtYH-lUVc?si=7GVv5V0MX6tI8nO_?autoplay=1&mute=1"
                    data-toggle="modal"
                    data-target="#myModal"
                    title={t("pfmVideoTitle")}
                    itemProp="url"
                  >
                    <img src={Playbtn} alt={t("playButton")} loading="lazy" />
                  </Link>
                  <img
                    className="spin_text"
                    src={Videobtn}
                    alt={t("playVideoText")}
                    loading="lazy"
                  />
                </div>
              </div>
              <div
                className="btn_block"
                data-aos="fade-up"
                data-aos-duration={1500}
              >
                <div className="btn_bottom" />
              </div>
            </div>
          </div>
        </div>
      </section>

      {ytshow && (
        <div
          className="modal fade youtube-video show"
          id="myModal"
          tabIndex={-1}
          style={{ display: "block", paddingRight: 17 }}
          aria-modal="true"
          role="dialog"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content" onClick={() => setytShow(false)}>
              <button
                id="close-video"
                type="button"
                className="button btn btn-default text-right"
                data-dismiss="modal"
                onClick={() => setytShow(false)}
                aria-label={t("closeVideo")}
              >
                <i className="icofont-close-line-circled" />
              </button>
              <div className="modal-body">
                <div id="video-container" className="video-container">
                  <iframe
                    id="youtubevideo"
                    width={640}
                    height={360}
                    allowFullScreen=""
                    src="https://youtube.com/embed/bXqtYH-lUVc?si=7GVv5V0MX6tI8nO_?autoplay=1&mute=1"
                    title={t("pfmVideoTitle")}
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="modal-footer"></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Aboutus;
