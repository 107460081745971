import React from "react";
import Banner1 from "../../assets/images/hero_img11.png";
import QRcode from "../../assets/images/qrcode.png";
import Banner2 from "../../assets/images/hero_image12.png";
import { useTranslation } from "react-i18next";

function Banner() {
  const { t } = useTranslation();
  return (
    <>
      <section
        className="banner_section"
        id="banner_section"
        itemScope
        itemType="https://schema.org/WPHeader"
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-3 col-6"
              data-aos="fade-up"
              data-aos-duration={1500}
              itemScope
              itemType="https://schema.org/ImageObject"
            >
              <div className="hero_imgleft">
                <img
                  src={Banner1}
                  alt={t("bannerImageAlt1")}
                  title={t("bannerImageTitle1")}
                  loading="eager"
                  itemProp="contentUrl"
                />
              </div>
            </div>
            <div
              className="col-lg-6 col-12 banner_content_colom"
              data-aos="fade-up"
              data-aos-duration={1500}
              itemScope
              itemType="https://schema.org/WebPageElement"
            >
              <div className="banner_text">
                <h1 itemProp="headline"> PFM </h1>
                <h2 itemProp="alternativeHeadline">
                  {t("racketSportsHeading")}
                </h2>
                <p itemProp="description">{t("racketSportsDescription")}</p>
                <div
                  className="qrcode"
                  itemScope
                  itemType="https://schema.org/SoftwareApplication"
                >
                  <img
                    src={QRcode}
                    alt={t("qrCodeAlt")}
                    title={t("qrCodeTitle")}
                    loading="lazy"
                    itemProp="image"
                  />
                  <p itemProp="downloadUrl">{t("appDownloadInfo")}</p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-6"
              data-aos="fade-up"
              data-aos-duration={1500}
              itemScope
              itemType="https://schema.org/ImageObject"
            >
              <div className="hero_img_right">
                <img
                  src={Banner2}
                  alt={t("bannerImageAlt2")}
                  title={t("bannerImageTitle2")}
                  loading="eager"
                  itemProp="contentUrl"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Banner;
