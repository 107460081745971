import React from "react";

const ServiceCard = ({ title, content, onClick }) => {
  return (
    <div
      className="service-card"
      onClick={onClick}
      style={{ cursor: "pointer" }}
      itemScope
      itemType="https://schema.org/Service"
      role="button"
      tabIndex={0}
      onKeyPress={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          onClick();
        }
      }}
    >
      <h3 className="service-card-title" itemProp="name">
        {title}
      </h3>
      <p className="service-card-content" itemProp="description">
        {content}
      </p>
    </div>
  );
};

export default ServiceCard;
