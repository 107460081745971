import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

const ClubSEO = ({ club }) => {
  const { t, i18n } = useTranslation();

  const getClubName = () => {
    return club[`clubName${i18n.language}`] || club.clubNametr;
  };

  const getClubDesc = () => {
    return club[`clubDesc${i18n.language}`] || club.clubDesctr;
  };

  const cleanHTML = (html) => {
    if (!html) return "";
    return html.replace(/<[^>]*>/g, "");
  };

  const getAlternateUrls = () => {
    const baseUrl = "https://www.playformore.app";
    const currentPath = `/club/${club.urlSlug}`;

    return [
      { url: `${baseUrl}${currentPath}`, hreflang: "tr" },
      { url: `${baseUrl}/en${currentPath}`, hreflang: "en" },
      { url: `${baseUrl}/ru${currentPath}`, hreflang: "ru" },
      { url: `${baseUrl}${currentPath}`, hreflang: "x-default" },
    ];
  };

  const getCurrentUrl = () => {
    const baseUrl = "https://www.playformore.app";
    const langPrefix = i18n.language !== "tr" ? `/${i18n.language}` : "";
    return `${baseUrl}${langPrefix}/club/${club.urlSlug}`;
  };

  const getClubImage = () => {
    if (club.clubPhotos && club.clubPhotos.length > 0) {
      return `https://cdn.pfmtennis.com/clubphotos/${club.clubPhotos[0].photoUrl}`;
    }
    return "https://cdn.pfmtennis.com/default-club-image.jpg";
  };

  const getKeywords = () => {
    const city = club.clubCity || "";
    const name = getClubName();

    const keywords = {
      tr: `${name}, ${city} tenis kulübü, ${city} padel kulübü, ${city} raket sporları, kort rezervasyonu, tenis dersleri, padel dersleri, raket sporları dersleri, ${city} tenis kortu, ${city} padel kortu, tenis antrenörü, tenis akademisi, raket sporları akademisi, tenis ligleri, padel ligleri, raket sporları etkinlikleri`,
      en: `${name}, ${city} tennis club, ${city} padel club, ${city} racket sports, court reservation, tennis lessons, padel lessons, racket sports lessons, ${city} tennis court, ${city} padel court, tennis coach, tennis academy, racket sports academy, tennis leagues, padel leagues, racket sports events`,
      ru: `${name}, теннисный клуб ${city}, клуб падел ${city}, ракеточные виды спорта ${city}, бронирование корта, уроки тенниса, уроки падела, уроки ракеточных видов спорта, теннисный корт ${city}, корт для падела ${city}, тренер по теннису, теннисная академия, академия ракеточных видов спорта, теннисные лиги, лиги падел, мероприятия по ракеточным видам спорта`,
    };

    return keywords[i18n.language] || keywords.tr;
  };

  const getClubSchema = () => {
    const schema = {
      "@context": "https://schema.org",
      "@type": "SportsActivityLocation",
      "@id": getCurrentUrl(),
      name: getClubName(),
      url: getCurrentUrl(),
      description: cleanHTML(getClubDesc()),
      telephone: club.clubPhone,
      email: club.clubEMail,
      areaServed: club.clubCity,
      priceRange: "$$",
      amenityFeature: [
        {
          "@type": "LocationFeatureSpecification",
          name: t("tennisCourts"),
          value: true,
        },
        {
          "@type": "LocationFeatureSpecification",
          name: t("padelCourts"),
          value: club.hasPadelCourts || false,
        },
        {
          "@type": "LocationFeatureSpecification",
          name: t("pickleballCourts"),
          value: club.hasPickleballCourts || false,
        },
        {
          "@type": "LocationFeatureSpecification",
          name: t("showerFacilities"),
          value: club.hasShowers || true,
        },
        {
          "@type": "LocationFeatureSpecification",
          name: t("parkingFacilities"),
          value: club.hasParking || true,
        },
      ],
      image: getClubImage(),
      address: {
        "@type": "PostalAddress",
        streetAddress: club.clubAddress,
        addressLocality: club.clubCity,
        addressRegion: club.clubCity,
        addressCountry: "TR",
      },
      geo: {
        "@type": "GeoCoordinates",
        latitude: club.clubLat,
        longitude: club.clubLong,
      },
      openingHoursSpecification: club.clubWorkingHours
        ? [
            {
              "@type": "OpeningHoursSpecification",
              dayOfWeek: [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday",
              ],
              opens: club.clubWorkingHours,
              closes: club.clubWorkingHours,
            },
          ]
        : undefined,
    };

    // Kort bilgileri
    if (club.clubCourts && club.clubCourts.length > 0) {
      schema.amenityFeature = club.clubCourts.map((court) => ({
        "@type": "LocationFeatureSpecification",
        name: court.courtName,
        value: court.courtType,
      }));
    }

    // Değerlendirme bilgileri
    if (club.clubRating) {
      schema.aggregateRating = {
        "@type": "AggregateRating",
        ratingValue: club.clubRating,
        ratingCount: club.clubRatingCount || 0,
        bestRating: "5",
        worstRating: "1",
      };
    }

    // Fotoğraf galerisi ekle
    if (club.clubPhotos && club.clubPhotos.length > 0) {
      schema.photo = club.clubPhotos.map((photo) => ({
        "@type": "ImageObject",
        contentUrl: `https://cdn.pfmtennis.com/clubphotos/${photo.photoUrl}`,
      }));
    }

    return schema;
  };

  return (
    <Helmet>
      <html lang={i18n.language} />
      <title>{`${getClubName()} - ${t("tennisClub")} | Play For More`}</title>
      <meta name="description" content={cleanHTML(getClubDesc())} />
      <meta name="keywords" content={getKeywords()} />
      <meta name="language" content={i18n.language} />
      <meta name="robots" content="index, follow" />
      <meta name="author" content="Play For More" />
      <meta name="geo.region" content="TR" />
      <meta name="geo.placename" content={club.clubCity} />
      <meta name="geo.position" content={`${club.clubLat};${club.clubLong}`} />
      <meta name="ICBM" content={`${club.clubLat}, ${club.clubLong}`} />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="business.business" />
      <meta property="og:url" content={getCurrentUrl()} />
      <meta
        property="og:title"
        content={`${getClubName()} - ${t("tennisClub")} | Play For More`}
      />
      <meta property="og:description" content={cleanHTML(getClubDesc())} />
      <meta property="og:image" content={getClubImage()} />
      <meta property="og:locale" content={i18n.language} />
      <meta property="og:site_name" content="Play For More" />
      <meta
        property="business:contact_data:street_address"
        content={club.clubAddress}
      />
      <meta property="business:contact_data:locality" content={club.clubCity} />
      <meta property="business:contact_data:country_name" content="Turkey" />
      <meta
        property="business:contact_data:phone_number"
        content={club.clubPhone}
      />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={getCurrentUrl()} />
      <meta
        name="twitter:title"
        content={`${getClubName()} - ${t("tennisClub")} | Play For More`}
      />
      <meta name="twitter:description" content={cleanHTML(getClubDesc())} />
      <meta name="twitter:image" content={getClubImage()} />

      {/* Dil Alternatifleri */}
      {getAlternateUrls().map(({ url, hreflang }) => (
        <link key={hreflang} rel="alternate" href={url} hreflang={hreflang} />
      ))}

      {/* Canonical URL */}
      <link rel="canonical" href={getCurrentUrl()} />

      {/* Schema.org İşaretlemesi */}
      <script type="application/ld+json">
        {JSON.stringify(getClubSchema())}
      </script>
    </Helmet>
  );
};

export default ClubSEO;
