import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import resPayments from "../../assets/images/club-panel/resPayments.png";
import coursePayments from "../../assets/images/club-panel/coursePayments.png";
import optionPayments from "../../assets/images/club-panel/optionPayments.png";
import leaguePayments from "../../assets/images/club-panel/leaguePayments.png";
import packagePayments from "../../assets/images/club-panel/packagePayments.png";
import { useTranslation } from "react-i18next";

function Finance({ id }) {
  const { t } = useTranslation();
  const paymentTypes = [
    {
      id: 1,
      title: t("reservationPayments"),
      description: t("reservationPaymentsDescription"),
      image: resPayments,
    },
    {
      id: 2,
      title: t("coursePayments"),
      description: t("coursePaymentsDescription"),
      image: coursePayments,
    },
    {
      id: 3,
      title: t("packagePayments"),
      description: t("packagePaymentsDescription"),
      image: packagePayments,
    },
    {
      id: 4,
      title: t("optionPayments"),
      description: t("optionPaymentsDescription"),
      image: optionPayments,
    },
    {
      id: 5,
      title: t("leaguePayments"),
      description: t("leaguePaymentsDescription"),
      image: leaguePayments,
    },
  ];

  return (
    <section className="row_am service_section about_service" id={id}>
      <div className="container">
        <div
          className="section_title"
          data-aos="fade-up"
          data-aos-duration={1500}
        >
          <h2>{t("financeModule")}</h2>
          <p>{t("financeModuleDescription2")}</p>
        </div>

        {/* Ödeme Türleri Slider Bölümü */}
        <div
          className="payment-types-slider mt-5"
          data-aos="fade-up"
          data-aos-duration={1500}
        >
          <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            spaceBetween={20}
            slidesPerView={1}
            navigation
            // pagination={{ clickable: true }}
            loop={true}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            breakpoints={{
              480: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              992: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              1200: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
            }}
            className="payment-swiper"
          >
            {paymentTypes.map((type) => (
              <SwiperSlide key={type.id}>
                <div className="payment-card">
                  <div className="payment-image">
                    <img
                      src={type.image}
                      alt={type.title}
                      className="img-fluid"
                    />
                  </div>
                  <div className="payment-content">
                    <h3>{type.title}</h3>
                    <p>{type.description}</p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
}

export default Finance;
