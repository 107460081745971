import React, { useEffect } from "react";
import Header from "../../Components/Header/Main";
import About from "../../Components/About/About";
import Whyus from "../../Components/About/Whyus";
import Ourvalue from "../../Components/About/Ourvalue";
import TextList from "../../Components/Home/TextList";
import Service from "../../Components/About/Service";
import Testimonial from "../../Components/About/Testimonial";
import Team from "../../Components/About/Team";
import Faq from "../../Components/About/Faq";
import App from "../../Components/About/App";
import Footer from "../../Components/Footer/Main";
import SEO from "../../Components/SEO";
import Aos from "aos";

function Main() {
  useEffect(() => {
    Aos.init();
    Aos.refresh();
  }, []);

  return (
    <>
      <SEO
        title={{
          tr: "PFM Hakkında - Tenis, Padel ve Raket Sporları İçin Yönetim Sistemi Yazılımı",
          en: "About PFM - Management System Software for Tennis, Padel and Racket Sports",
          ru: "О PFM - Программное обеспечение системы управления для тенниса, падела и ракеточных видов спорта",
        }}
        description={{
          tr: "PFM hakkında bilgi edinin. Tenis, padel ve raket sporları kulüpleri için geliştirilen profesyonel yönetim sistemi yazılımımızla tanışın. 25+ kulüp tarafından kullanılan çözümümüz.",
          en: "Learn about PFM. Meet our professional management system software developed for tennis, padel and racket sports clubs. Our solution used by 25+ clubs.",
          ru: "Узнайте о PFM. Познакомьтесь с нашим профессиональным программным обеспечением системы управления, разработанным для клубов тенниса, падела и ракеточных видов спорта. Наше решение используется более чем 25 клубами.",
        }}
        keywords={{
          tr: "PFM, PFM Hakkında, Play for more, Playformore, Tenis Kulüp Yönetim Sistemi, Padel Kulüp Yönetim Sistemi, Raket Sporları Yönetim Sistemi, Kulüp Yönetim Yazılımı, Tenis Kulübü Yazılımı, Kort Rezervasyon Sistemi, Raket Sporları Yazılımı, PFM Özellikleri, PFM Avantajları",
          en: "PFM, About PFM, Play for more, Playformore, Tennis Club Management System, Padel Club Management System, Racket Sports Management System, Club Management Software, Tennis Club Software, Court Reservation System, Racket Sports Software, PFM Features, PFM Advantages",
          ru: "PFM, О PFM, Play for more, Playformore, Система управления теннисным клубом, Система управления клубом падел, Система управления клубом ракеточных видов спорта, Программное обеспечение для управления клубом, Программное обеспечение для теннисного клуба, Система бронирования кортов, Программное обеспечение для ракеточных видов спорта, Особенности PFM, Преимущества PFM",
        }}
        ogTitle={{
          tr: "PFM Hakkında - Tenis, Padel ve Raket Sporları İçin Yönetim Sistemi Yazılımı",
          en: "About PFM - Management System Software for Tennis, Padel and Racket Sports",
          ru: "О PFM - Программное обеспечение системы управления для тенниса, падела и ракеточных видов спорта",
        }}
        ogDescription={{
          tr: "PFM hakkında bilgi edinin. Tenis, padel ve raket sporları kulüpleri için geliştirilen profesyonel yönetim sistemi yazılımımızla tanışın.",
          en: "Learn about PFM. Meet our professional management system software developed for tennis, padel and racket sports clubs.",
          ru: "Узнайте о PFM. Познакомьтесь с нашим профессиональным программным обеспечением системы управления, разработанным для клубов тенниса, падела и ракеточных видов спорта.",
        }}
        ogImage="https://www.playformore.app/assets/images/about-banner.jpg"
        ogUrl="https://www.playformore.app/about"
      />
      <Header />
      <div className="page_wrapper">
        <About />
        <Whyus />
        <Ourvalue />
        <TextList />
        <Service />
        <Testimonial />
        <Team />
        <Faq />
        <App />
        <Footer />
      </div>
    </>
  );
}

export default Main;
