import React, { useEffect } from "react";
// import Header from "../../Components/Header/Main";
import ClubPanelHeader from "../../Components/Header/ClubPanelHeader";
import ClubPanelBanner from "../../Components/ClubPanel/ClubPanelBanner";
import PanelModules from "../../Components/ClubPanel/PanelModules";
import ControlPanel from "../../Components/ClubPanel/ControlPanel";
import ClubMatches from "../../Components/ClubPanel/ClubMatches";
import TextList from "../../Components/ClubPanel/TextList";
import ClubMembers from "../../Components/ClubPanel/ClubMembers";
import Testimonial from "../../Components/ClubPanel/Testimonial";
import Academy from "../../Components/ClubPanel/Academy";
import Faq from "../../Components/ClubPanel/Faq";
import Team from "../../Components/ClubPanel/Team";
import Finance from "../../Components/ClubPanel/Finance";
import Leagues from "../../Components/ClubPanel/Leagues";
import Statistics from "../../Components/ClubPanel/Statistics";
import App from "../../Components/ClubPanel/App";
import Footer from "../../Components/Footer/Main";
import SEO from "../../Components/SEO";
import Aos from "aos";
import Settings from "../../Components/ClubPanel/Settings";
import Clients from "../../Components/ClubPanel/Clients";
import ScrollToTop from "../../Components/Common/ScrollToTop";
import HowWork from "../../Components/ClubPanel/HowWork";
import { useTranslation } from "react-i18next";

function Main() {
  const { i18n } = useTranslation();

  useEffect(() => {
    Aos.init();
    Aos.refresh();
  }, []);

  const clubPanelSchema = {
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    name: {
      tr: "PFM Kulüp Paneli - Profesyonel Tenis Kulübü Yönetim Sistemi",
      en: "PFM Club Panel - Professional Tennis Club Management System",
      ru: "PFM Панель клуба - Профессиональная система управления теннисным клубом",
    },
    description: {
      tr: "PFM Kulüp Paneli ile tenis kulübünüzü profesyonelce yönetin. Kort rezervasyonları, üye yönetimi, mali raporlar, personel yönetimi ve daha fazlası için kapsamlı çözüm.",
      en: "Manage your tennis club professionally with PFM Club Panel. Comprehensive solution for court reservations, member management, financial reports, staff management and more.",
      ru: "Управляйте своим теннисным клубом профессионально с PFM Панель клуба. Комплексное решение для бронирования кортов, управления членами, финансовых отчетов, управления персоналом и многого другого.",
    },
    applicationCategory: "BusinessApplication",
    operatingSystem: "Web",
    offers: {
      "@type": "Offer",
      price: "0",
      priceCurrency: "TRY",
    },
    featureList: {
      tr: [
        "Kort yönetimi ve rezervasyon sistemi",
        "Üye ve müşteri yönetimi",
        "Mali raporlar ve gelir takibi",
        "Personel ve antrenör yönetimi",
        "Ders ve etkinlik planlaması",
        "Ekipman ve envanter takibi",
        "Online ödeme sistemi entegrasyonu",
        "Mobil uygulama entegrasyonu",
      ],
      en: [
        "Court management and reservation system",
        "Member and customer management",
        "Financial reports and income tracking",
        "Staff and coach management",
        "Lesson and event planning",
        "Equipment and inventory tracking",
        "Online payment system integration",
        "Mobile app integration",
      ],
      ru: [
        "Управление кортами и система бронирования",
        "Управление членами и клиентами",
        "Финансовые отчеты и отслеживание доходов",
        "Управление персоналом и тренерами",
        "Планирование уроков и мероприятий",
        "Отслеживание оборудования и инвентаря",
        "Интеграция системы онлайн-платежей",
        "Интеграция мобильного приложения",
      ],
    },
    inLanguage: i18n.language,
    url: "https://www.playformore.app/club-panel",
    image: "https://www.playformore.app/assets/images/logo.png",
    applicationSubCategory: "TennisClubManagement",
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: "4.8",
      ratingCount: "150",
    },
  };

  return (
    <>
      <SEO
        title={{
          tr: "PFM Kulüp Yönetim Sistemi - Tenis, Padel ve Raket Sporları Kulüpleri İçin Profesyonel Yazılım",
          en: "PFM Club Management System - Professional Software for Tennis, Padel and Racket Sports Clubs",
          ru: "Система управления клубом PFM - Профессиональное программное обеспечение для клубов тенниса, падела и ракеточных видов спорта",
        }}
        description={{
          tr: "PFM Kulüp Yönetim Sistemi ile tenis, padel ve raket sporları kulübünüzü profesyonelce yönetin. Kort rezervasyonları, üye yönetimi, mali raporlar, personel yönetimi, ders planlama ve daha fazlası için eksiksiz çözüm.",
          en: "Professionally manage your tennis, padel and racket sports club with PFM Club Management System. Complete solution for court reservations, member management, financial reports, staff management, lesson planning and more.",
          ru: "Профессионально управляйте своим клубом тенниса, падела и ракеточных видов спорта с Системой управления клубом PFM. Полное решение для бронирования кортов, управления членами, финансовых отчетов, управления персоналом, планирования уроков и многого другого.",
        }}
        keywords={{
          tr: "PFM, PFM App, PFM Kulüp Yönetim Sistemi, PFM Yöneticisi, Play for more, Playformore, Tenis Kulüp Yönetim Sistemi, Padel Kulüp Yönetim Sistemi, Raket Sporları Yönetim Sistemi, Kulüp Yönetim Sistemi, Tenis Kulüp Yönetim Yazılımı, Padel Kulüp Yönetim Yazılımı, Raket Sporları Yönetim Yazılımı, Kulüp Yönetim Yazılımı, kort yönetimi, üye yönetimi, mali raporlar, personel yönetimi, ders planlaması, ekipman takibi, online ödeme sistemi, tenis akademisi yönetimi, personel ve antrenör yönetimi, kort rezervasyon yönetimi",
          en: "PFM, PFM App, PFM Club Management System, PFM Manager, Play for more, Playformore, Tennis Club Management System, Padel Club Management System, Racket Sports Management System, Club Management System, Tennis Club Management Software, Padel Club Management Software, Racket Sports Management Software, Club Management Software, court management, member management, financial reports, staff management, lesson planning, equipment tracking, online payment system, tennis academy management, staff and coach management, court reservation management",
          ru: "PFM, PFM приложение, Система управления клубом PFM, Менеджер PFM, Play for more, Playformore, Система управления теннисным клубом, Система управления клубом падел, Система управления клубом ракеточных видов спорта, Система управления клубом, Программное обеспечение для управления теннисным клубом, Программное обеспечение для управления клубом падел, Программное обеспечение для управления клубом ракеточных видов спорта, Программное обеспечение для управления клубом, управление кортами, управление членами, финансовые отчеты, управление персоналом, планирование уроков, отслеживание оборудования, система онлайн-платежей, управление теннисной академией, управление персоналом и тренерами, управление бронированием кортов",
        }}
        ogTitle={{
          tr: "PFM Kulüp Yönetim Sistemi - Tenis, Padel ve Raket Sporları Kulüpleri İçin Profesyonel Yazılım",
          en: "PFM Club Management System - Professional Software for Tennis, Padel and Racket Sports Clubs",
          ru: "Система управления клубом PFM - Профессиональное программное обеспечение для клубов тенниса, падела и ракеточных видов спорта",
        }}
        ogDescription={{
          tr: "PFM Kulüp Yönetim Sistemi ile tenis, padel ve raket sporları kulübünüzü profesyonelce yönetin. Kort rezervasyonları, üye yönetimi, mali raporlar ve daha fazlası için eksiksiz çözüm.",
          en: "Professionally manage your tennis, padel and racket sports club with PFM Club Management System. Complete solution for court reservations, member management, financial reports and more.",
          ru: "Профессионально управляйте своим клубом тенниса, падела и ракеточных видов спорта с Системой управления клубом PFM. Полное решение для бронирования кортов, управления членами, финансовых отчетов и многого другого.",
        }}
        ogImage="https://www.playformore.app/assets/images/logo.png"
        ogUrl="https://www.playformore.app/club-panel"
        twitterTitle={{
          tr: "PFM Kulüp Paneli - Profesyonel Tenis Kulübü Yönetim Sistemi",
          en: "PFM Club Panel - Professional Tennis Club Management System",
          ru: "PFM Панель клуба - Профессиональная система управления теннисным клубом",
        }}
        twitterDescription={{
          tr: "PFM Kulüp Paneli ile tenis kulübünüzü profesyonelce yönetin. Kort rezervasyonları, üye yönetimi, mali raporlar ve daha fazlası için kapsamlı çözüm.",
          en: "Manage your tennis club professionally with PFM Club Panel. Comprehensive solution for court reservations, member management, financial reports and more.",
          ru: "Управляйте своим теннисным клубом профессионально с PFM Панель клуба. Комплексное решение для бронирования кортов, управления членами, финансовых отчетов и многого другого.",
        }}
        twitterImage="https://www.playformore.app/assets/images/logo.png"
        schema={clubPanelSchema}
      />
      <ClubPanelHeader />
      <div
        className="page_wrapper"
        itemScope
        itemType="https://schema.org/WebPage"
      >
        <ClubPanelBanner />
        <PanelModules />
        <ControlPanel id="control-panel" />
        <ClubMatches id="club-matches" />
        {/* <TextList /> */}
        <ClubMembers id="club-members" />
        {/* <Testimonial /> */}
        <Academy id="academy" />
        <Team id="team" />
        <Finance id="finance" />
        <Leagues id="leagues" />
        <Statistics id="statistics" />
        <Settings id="settings" />
        <Clients id="clients" />
        <HowWork />
        {/* <Faq />
        <App /> */}
        <Footer />
      </div>
      <ScrollToTop />
    </>
  );
}

export default Main;
