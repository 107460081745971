import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";

//*images
import heatMap from "../../assets/images/club-panel/heatMap.png";
import resAnalysis from "../../assets/images/club-panel/resAnalysis.png";
import hourAnalysis from "../../assets/images/club-panel/hourAnalysis.png";
import payAnalysis from "../../assets/images/club-panel/payAnalysis.png";
import statisticsLanding from "../../assets/images/club-panel/statisticsLanding.png";
import { useTranslation } from "react-i18next";

function Statistics({ id }) {
  const { t } = useTranslation();

  const statisticsSpecs = [
    t("viewAllSummaryInformation"),
    t("detailedAnalysisPage"),
    t("courtReservationAnalysis"),
    t("courtHoursAnalysis"),
    t("paymentsAnalysis"),
    t("heatMapAnalysis"),
    t("viewGraphsWeeklyMonthlyYearly"),
    t("exportGraphData"),
  ];

  const graphPages = [
    { id: 1, img: payAnalysis, title: t("paymentsGraph") },
    { id: 2, img: resAnalysis, title: t("reservationGraph") },
    { id: 3, img: hourAnalysis, title: t("courtHourAnalysis") },
    { id: 4, img: heatMap, title: t("heatGraph") },
  ];

  return (
    <section className="row_am service_section about_service" id={id}>
      <div className="container">
        <div
          className="section_title"
          data-aos="fade-up"
          data-aos-duration={1500}
        >
          <h2>{t("statisticsModule")}</h2>
          <p>{t("statisticsModuleDescription2")}</p>
        </div>

        <div className="league-features-container mt-5">
          <div className="row align-items-center">
            <div
              className="col-lg-6 col-md-12"
              data-aos="fade-left"
              data-aos-duration={1200}
            >
              <div className="service_text">
                <h4 className="feature-title mb-4">
                  <span>{t("statisticsModuleFeatures")}</span>
                </h4>

                <ul className="feature_list">
                  {statisticsSpecs.map((feature, index) => (
                    <div
                      key={index}
                      className="feature-item-minimal animated-item"
                      style={{ animationDelay: `${index * 500}ms` }}
                    >
                      <div className="feature-icon-minimal">
                        <svg
                          className="checkmark-icon"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="checkmark-circle"
                            cx="12"
                            cy="12"
                            r="11"
                            fill="#f0f7ff"
                          />
                          <path
                            className="checkmark-check"
                            d="M7 13l3 3 7-7"
                            stroke="#4169e1"
                            strokeWidth="2"
                            fill="none"
                          />
                        </svg>
                      </div>
                      <div className="feature-text-minimal">{feature}</div>
                    </div>
                    // <li key={index}>
                    //   <div className="icon">
                    //     <span>
                    //       <i className="icofont-check" />
                    //     </span>
                    //   </div>
                    //   <div className="text">
                    //     <p>{feature}</p>
                    //   </div>
                    // </li>
                  ))}
                </ul>
              </div>
            </div>
            <div
              className="col-lg-6 col-md-12 mb-4 mb-lg-0"
              data-aos="fade-right"
              data-aos-duration={1200}
            >
              <div className="league-image-wrapper">
                <img
                  src={statisticsLanding}
                  alt="İstatistik Modülü"
                  className="img-fluid rounded shadow-sm league-image"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className="statistics-gallery mt-5"
          data-aos="fade-up"
          data-aos-duration={1500}
        >
          <div className="container">
            <h3 className="text-center mb-4">{t("statisticsGraphs")}</h3>
            <div
              className="payment-types-slider mt-5"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <Swiper
                modules={[Navigation, Pagination, Autoplay]}
                spaceBetween={20}
                slidesPerView={1}
                navigation
                // pagination={{ clickable: true }}
                loop={true}
                autoplay={{ delay: 3000, disableOnInteraction: false }}
                breakpoints={{
                  480: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 2,
                  },
                  992: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                  },
                  1200: {
                    slidesPerView: 2,
                    spaceBetween: 40,
                  },
                }}
                className="payment-swiper"
              >
                {graphPages.map((type) => (
                  <SwiperSlide key={type.id}>
                    <div className="payment-card">
                      <div className="payment-image">
                        <img
                          src={type.img}
                          alt={type.title}
                          className="img-fluid"
                        />
                      </div>
                      <div className="payment-content">
                        <h3>{type.title}</h3>
                        <p>{type.description}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Statistics;
