import React from "react";
import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import Dotsimg from "../../assets/images/yellow_dotes.png";
import Screen from "../../assets/images/downloadScreen.png";
import Payimg from "../../assets/images/googleplay.png";
import Appimg from "../../assets/images/appstorebtn.png";
import { useTranslation } from "react-i18next";

const Textslider = {
  responsive: {
    0: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 4,
    },
  },
};

function Text() {
  const { t } = useTranslation();
  const generateTextBlocks = (count) => {
    const textBlocks = [];
    for (let i = 0; i < count; i++) {
      textBlocks.push(
        <SwiperSlide
          className="item"
          key={i}
          itemProp="itemListElement"
          itemScope
          itemType="https://schema.org/ListItem"
        >
          <div className="text_block">
            <span itemProp="name">{t("downloadPfm")}</span>
            <span className="mark_star" aria-hidden="true">
              •
            </span>
          </div>
        </SwiperSlide>
      );
    }
    return textBlocks;
  };

  return (
    <>
      <div
        className="text_list_section row_am downaload_section"
        data-aos="fade-in"
        data-aos-duration={1500}
        itemScope
        itemType="https://schema.org/SoftwareApplication"
      >
        <div className="container">
          <div
            className="yellow_dotes"
            itemScope
            itemType="https://schema.org/ImageObject"
          >
            <img
              src={Dotsimg}
              alt={t("decorativeDots")}
              loading="lazy"
              itemProp="contentUrl"
            />
          </div>
          <div className="center_screen">
            <div
              className="img"
              itemScope
              itemType="https://schema.org/ImageObject"
            >
              <img
                src={Screen}
                alt={t("appScreenshot")}
                loading="lazy"
                itemProp="contentUrl"
              />
            </div>
            <ul
              className="app_btn"
              itemScope
              itemType="https://schema.org/ItemList"
            >
              <li
                itemProp="itemListElement"
                itemScope
                itemType="https://schema.org/ListItem"
              >
                <Link
                  to="https://play.google.com/store/apps/details?id=com.iskc.pfm&hl=en_US"
                  target="blank"
                  itemProp="url"
                >
                  <img
                    className="blue_img"
                    src={Payimg}
                    alt={t("googlePlayStore")}
                    loading="lazy"
                    itemProp="image"
                  />
                </Link>
              </li>
              <li
                itemProp="itemListElement"
                itemScope
                itemType="https://schema.org/ListItem"
              >
                <Link
                  to="https://apps.apple.com/tr/app/pfm/id1659862462?l=tr"
                  target="blank"
                  itemProp="url"
                >
                  <img
                    className="blue_img"
                    src={Appimg}
                    alt={t("appStore")}
                    loading="lazy"
                    itemProp="image"
                  />
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="background_slider">
          <div className="dowanload_slider">
            {/* <div className="downlist">generateTextBlocks(5)</div> */}
          </div>
          <div className="slider_block">
            <Swiper
              className="Swiper-Text"
              id="text_list_flow_download"
              {...Textslider}
              slidesPerView={4}
            >
              {generateTextBlocks(6)}
            </Swiper>
          </div>
          <div className="dowanload_slider">
            <div className="downlist">{/*generateTextBlocks(4)*/}</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Text;
