import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import service01 from "../../assets/images/service01.png";
import service02 from "../../assets/images/service02.png";
import service03 from "../../assets/images/service03.png";
import service04 from "../../assets/images/service04.png";

function Service() {
  const { t } = useTranslation();

  const servicesData = [
    {
      icon: "icofont-tasks-alt",
      title: t("hundredsOfClubs"),
      subtitle: t("listNearestClubs"),
      description: t("clubsDescription"),
      features: [
        t("filterByLocation"),
        t("clubsByGameType"),
        t("seeReviewsOrRatings"),
      ],
      imageSrc: service01,
    },
    {
      icon: "icofont-ui-clock",
      title: t("hundredsOfPlayers"),
      subtitle: t("listNearestPlayers"),
      description: t("playersDescription"),
      features: [
        t("filterByLocation"),
        t("seeSkillLevel"),
        t("viewPreviousMatches"),
      ],
      imageSrc: service02,
    },
    {
      icon: "icofont-list",
      title: t("stayInTouch"),
      subtitle: t("communicateWithPlayers"),
      description: t("communicationDescription"),
      features: [t("instantPrivateChat")],
      imageSrc: service03,
    },
    {
      icon: "icofont-list",
      title: t("joinLeagues"),
      subtitle: t("joinLocalLeagues"),
      description: t("leaguesDescription"),
      features: [t("clubSpecificLeagues"), t("configurePrivateLeague")],
      imageSrc: service04,
    },
  ];

  return (
    <section
      className="row_am service_section"
      id="service_section"
      itemScope
      itemType="https://schema.org/Service"
    >
      <div className="container">
        <div
          className="section_title"
          data-aos="fade-up"
          data-aos-duration={1500}
        >
          <span className="title_badge mb-1" itemProp="name">
            {t("features2")}
          </span>
          <h2 itemProp="headline">
            <span itemProp="name">{t("pfm")}</span>{" "}
            {t("featuredFeaturesPartOne")} <br />
            {t("featuredFeaturesPartTwo")}
          </h2>
        </div>
        {servicesData.map((service, index) => (
          <div
            className={`row service_blocks ${
              index % 2 === 0 ? "" : "flex-row-reverse"
            }`}
            key={index}
            itemScope
            itemType="https://schema.org/Service"
          >
            <div className="col-md-6">
              <div
                className={`service_text ${
                  index % 2 === 0 ? "" : "right_side"
                }`}
                data-aos="fade-up"
                data-aos-duration={1500}
              >
                <div className="service_badge">
                  <i className={service.icon} />
                  <span itemProp="name">{service.title}</span>
                </div>
                <h2 itemProp="alternateName">
                  <span>{service.subtitle}</span>
                </h2>
                <p itemProp="description">{service.description}</p>
                <ul
                  className={`${
                    index % 2 === 0 ? "listing_block" : "feature_list"
                  }`}
                  itemScope
                  itemType="https://schema.org/ItemList"
                >
                  {service.features.map((feature, featureIndex) => (
                    <li
                      key={featureIndex}
                      itemProp="itemListElement"
                      itemScope
                      itemType="https://schema.org/ListItem"
                    >
                      <div className="icon">
                        <span>
                          <i className="icofont-ui-check" />
                        </span>
                      </div>
                      <div className="text">
                        <h3 itemProp="name">{feature}</h3>
                      </div>
                    </li>
                  ))}
                </ul>
                {/* <div className="btn_block">
                                    <Link to="/contact" className="btn puprple_btn ml-0">
                                        Start Free Trial
                                    </Link>
                                    <div className="btn_bottom" />
                                </div>*/}
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="img"
                data-aos="fade-up"
                data-aos-duration={1500}
                itemScope
                itemType="https://schema.org/ImageObject"
              >
                <img
                  src={service.imageSrc}
                  alt={t(`serviceImageAlt${index + 1}`)}
                  loading="lazy"
                  itemProp="contentUrl"
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Service;
