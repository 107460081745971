import React from "react";
import Dots from "../../assets/images/blue_dotes.png";
import Unique1 from "../../assets/images/feature01.png";
import Unique2 from "../../assets/images/feature02.png";
import feature03 from "../../assets/images/feature03.png";
import feature04 from "../../assets/images/feature04.png";
import feature05 from "../../assets/images/feature05.png";
import Unique6 from "../../assets/images/unique_6.png";
import feature06 from "../../assets/images/feature06.png";
import { useTranslation } from "react-i18next";

function Benefits() {
  const { t } = useTranslation();
  return (
    <>
      <section
        className="unique_section white_text"
        itemScope
        itemType="https://schema.org/ItemList"
      >
        <div className="unique_inner">
          {/* <div className="dotes_blue">
            <img src={Dots} alt="image" />
          </div> */}
          <div className="container">
            <div
              className="section_title"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <span className="title_badge" itemProp="name">
                {t("sectionBadge")}
              </span>
              <h2 itemProp="headline">{t("sectionTitle")}</h2>
            </div>
            <div className="row">
              <div
                className="col-lg-3 col-md-6 mb_5"
                data-aos="fade-up"
                data-aos-duration={1500}
                itemProp="itemListElement"
                itemScope
                itemType="https://schema.org/ListItem"
              >
                <div className="unique_box">
                  <div className="text">
                    <h3 itemProp="name">{t("memberPoolTitle")}</h3>
                    <p itemProp="description">{t("memberPoolDesc")}</p>
                  </div>
                  <div
                    className="img_block"
                    itemScope
                    itemType="https://schema.org/ImageObject"
                  >
                    <img
                      src={Unique1}
                      alt={t("memberPoolImageAlt")}
                      loading="lazy"
                      itemProp="contentUrl"
                    />
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 mb_5"
                data-aos="fade-up"
                data-aos-duration={1500}
                itemProp="itemListElement"
                itemScope
                itemType="https://schema.org/ListItem"
              >
                <div className="unique_box">
                  <div className="text">
                    <h3 itemProp="name">
                      {t("advancedReservationCalendarTitle")}
                    </h3>
                    <p itemProp="description">
                      {t("advancedReservationCalendarDesc")}
                    </p>
                  </div>
                  <div
                    className="img_block"
                    itemScope
                    itemType="https://schema.org/ImageObject"
                  >
                    <img
                      src={Unique2}
                      alt={t("reservationCalendarImageAlt")}
                      loading="lazy"
                      itemProp="contentUrl"
                    />
                  </div>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-6 mb_5"
                data-aos="fade-up"
                data-aos-duration={1500}
                itemProp="itemListElement"
                itemScope
                itemType="https://schema.org/ListItem"
              >
                <div className="unique_box">
                  <div className="text">
                    <h3 itemProp="name">{t("enterYourScoreTitle")}</h3>
                    <p itemProp="description">{t("enterYourScoreDesc")}</p>
                  </div>
                  <div
                    className="img_block"
                    itemScope
                    itemType="https://schema.org/ImageObject"
                  >
                    <img
                      src={feature03}
                      alt={t("scoreEntryImageAlt")}
                      loading="lazy"
                      itemProp="contentUrl"
                    />
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-6 mb_5"
                data-aos="fade-up"
                data-aos-duration={1500}
                itemProp="itemListElement"
                itemScope
                itemType="https://schema.org/ListItem"
              >
                <div className="unique_box">
                  <div className="text">
                    <h3 itemProp="name">{t("nearbyPlayersTitle")}</h3>
                    <p itemProp="description">{t("nearbyPlayersDesc")}</p>
                  </div>
                  <div
                    className="img_block"
                    itemScope
                    itemType="https://schema.org/ImageObject"
                  >
                    <img
                      src={feature04}
                      alt={t("nearbyPlayersImageAlt")}
                      loading="lazy"
                      itemProp="contentUrl"
                    />
                  </div>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-6 mb_5"
                data-aos="fade-up"
                data-aos-duration={1500}
                itemProp="itemListElement"
                itemScope
                itemType="https://schema.org/ListItem"
              >
                <div className="unique_box">
                  <div className="text">
                    <h3 itemProp="name">{t("gameRequestsTitle")}</h3>
                    <p itemProp="description">{t("gameRequestsDesc")}</p>
                  </div>
                  <div
                    className="img_block"
                    itemScope
                    itemType="https://schema.org/ImageObject"
                  >
                    <img
                      src={feature05}
                      alt={t("gameRequestsImageAlt")}
                      loading="lazy"
                      itemProp="contentUrl"
                    />
                  </div>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-6 mb_5"
                data-aos="fade-up"
                data-aos-duration={1500}
                itemProp="itemListElement"
                itemScope
                itemType="https://schema.org/ListItem"
              >
                <div className="unique_box">
                  <div className="text">
                    <h3 itemProp="name">{t("personalGameScheduleTitle")}</h3>
                    <p itemProp="description">
                      {t("personalGameScheduleDesc")}
                    </p>
                  </div>
                  <div
                    className="img_block"
                    itemScope
                    itemType="https://schema.org/ImageObject"
                  >
                    <img
                      src={feature06}
                      alt={t("gameScheduleImageAlt")}
                      loading="lazy"
                      itemProp="contentUrl"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Benefits;
