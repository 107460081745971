import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/whiteLogo.png";
import LanguageSwitcher from "../LanguageSwitcher";
import { useTranslation } from "react-i18next";
import "../../assets/css/clubDetailStyles.css";
import { FaSignInAlt } from "react-icons/fa";

function ClubDetailHeader({ club }) {
  const [headerScroll, setheaderScroll] = useState(false);
  const [mobile, setmobile] = useState();
  const { t, i18n } = useTranslation();
  useEffect(() => {
    const fixedHeader = () => {
      if (window.pageYOffset > 150) {
        setheaderScroll(true);
      } else {
        setheaderScroll(false);
      }
    };
    window.addEventListener("scroll", fixedHeader);
  }, []);

  // Kulüp logosu varsa onu kullan, yoksa varsayılan logoyu kullan
  const logoSrc =
    club && club.clubLogo
      ? `https://cdn.pfmtennis.com/clubphotos/${club.clubLogo}`
      : logo;

  // Kulüp adı için dil seçimine göre uygun adı al
  const clubName =
    club && (i18n.language === "tr" ? club.clubNametr : club.clubNameen);

  return (
    <>
      <header
        className={
          headerScroll
            ? "fixed fix_style club-detail-header"
            : "fixed header_color club-detail-header"
        }
      >
        <div className="container">
          <nav className="navbar navbar-expand-lg ">
            <div className="navbar-brand">
              {/* <Link to="/">
             
                <img src={logo} alt="PFM Logo" className="pfm-logo" />
              </Link> */}
              {/* Kulüp logosu ve adı yan yana */}
              {club && club.clubLogo && (
                <Link to="/" className="club-brand-container">
                  <img
                    src={logoSrc}
                    alt={club.clubNametr || "Kulüp Logo"}
                    className="club-logo"
                  />
                  <div className="club-name">
                    {clubName || club.clubNametr || "Kulüp Adı"}
                  </div>
                </Link>
              )}
            </div>
            <button
              className={`navbar-toggler ${mobile && "collapsed"}`}
              onClick={() => setmobile(mobile === true ? false : true)}
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <div
                className={`toggle-wrap ${mobile && "active"}`}
                onClick={() => setmobile(mobile === true ? false : true)}
              >
                <span className="toggle-bar"></span>
              </div>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <a className="nav-link" href="#club-about">
                    {t("aboutClub")}
                  </a>
                </li>

                <li className="nav-item">
                  <a className="nav-link" href="#club-gallery">
                    {t("gallery")}
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#club-contact">
                    {t("contact")}
                  </a>
                </li>
                {/* <li className="nav-item">
                  <a className="nav-link" href="#club-reviews">
                    {t("reviews")}
                  </a>
                </li> */}
                <li className="nav-item">
                  <a
                    className="nav-link club-panel-btn"
                    href="https://manager.playformore.app"
                    target="_blank"
                  >
                    <FaSignInAlt style={{ marginRight: "5px" }} />{" "}
                    {t("adminLogin")}
                  </a>
                </li>
                <li className="nav-link">
                  <LanguageSwitcher isClubDetail={true} />
                </li>
              </ul>
            </div>

            {mobile && (
              <>
                <div
                  className={`navbar-collapse collapse ${mobile && "show"}`}
                  id="navbarSupportedContent"
                  style={{}}
                >
                  <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                      <a className="nav-link" href="#club-about">
                        {t("aboutClub")}
                      </a>
                    </li>

                    <li className="nav-item">
                      <a className="nav-link" href="#club-gallery">
                        {t("gallery")}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#club-contact">
                        {t("contact")}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#club-reviews">
                        {t("reviews")}
                      </a>
                    </li>
                    <li className="nav-item active">
                      <a
                        className="nav-link club-panel-btn"
                        href="https://manager.playformore.app"
                        target="_blank"
                      >
                        <FaSignInAlt style={{ marginRight: "5px" }} />{" "}
                        {t("adminLogin")}
                      </a>
                    </li>
                    <li className="nav-item">
                      <LanguageSwitcher isClubDetail={true} />
                    </li>
                  </ul>
                </div>
              </>
            )}
          </nav>
        </div>
      </header>
    </>
  );
}

export default ClubDetailHeader;
