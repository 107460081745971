import React from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';

const Textslider = {
    responsive: {
        0: {
            items: 2,
        },
        600: {
            items: 3,
        },
        1000: {
            items: 4,
        },
    },
};

const textItems = [
    'Yakındaki Kulüpler',
    'Yakındaki Oyuncular',
    'Mesajlaşma',
    'Rezervayon Yönetimi',
    'Ligler',
    'Oyun Organize Etme',
    'Yakınındaki Antrenörler',
    'Oyun Havuzu',
];

function TextList() {
    return (
        <>
            <div className="text_list_section row_am" data-aos="fade-in" data-aos-duration={1500} >
                <div className="container">
                    <span className="title_badge down_fix">Neden PFM?</span>
                </div>
                <div className="slider_block">
                    <Swiper className="mySwiper" id="text_list_flow" {...Textslider} slidesPerView={2.1}>
                        {textItems.map((item, index) => (
                            <SwiperSlide key={index} className="item">
                                <div className="text_block">
                                    <span>{item}</span>
                                    <span className="mark_star">•</span>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </>
    );
}

export default TextList;
