import React from "react";
import { GiTennisRacket } from "react-icons/gi";
import { IoTrophySharp } from "react-icons/io5"; // Skor takibi için
import matchesPage from "../../assets/images/club-panel/matchesPage.png";
import enterScore from "../../assets/images/club-panel/enterScore.png";
import { useTranslation } from "react-i18next";

function ClubMatches({ id }) {
  const { t } = useTranslation();
  return (
    <section className="club-matches-section row_am" id={id}>
      <div className="container">
        <div
          className="section_title"
          data-aos="fade-up"
          data-aos-duration={1500}
        >
          <h2>{t("clubMatches")}</h2>
          <p>{t("clubMatchesDescription2")}</p>
        </div>

        <div className="matches-container">
          <div className="matches-content-left">
            <h5>{t("manageClubMatches")}</h5>
            <p>{t("manageClubMatchesDescription")}</p>

            <div className="features-box">
              <div className="feature-item">
                <div className="feature-icon">
                  <GiTennisRacket size={24} />
                </div>
                <div className="feature-content">
                  <h4>{t("detailedMatchList")}</h4>
                  <p>{t("detailedMatchListDescription")}</p>
                </div>
              </div>

              <div className="feature-item">
                <div className="feature-icon">
                  <IoTrophySharp size={24} />
                </div>
                <div className="feature-content">
                  <h4>{t("scoreEntry")}</h4>
                  <p>{t("scoreEntryDescription")}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="matches-content-right">
            <div className="image-stack">
              <div
                className="image-top"
                data-aos="fade-left"
                data-aos-delay="200"
              >
                <img src={matchesPage} alt={t("matchStatistics")} />
              </div>
              <div
                className="image-bottom"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <img src={enterScore} alt={t("matchScore")} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ClubMatches;
