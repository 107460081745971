import React, { useState } from "react";
import activeMembers from "../../assets/images/club-panel/activeMembers.png";
import { useTranslation } from "react-i18next";

function ClubMembers({ id }) {
  const { t } = useTranslation();

  const serviceFeatures = [
    t("newMemberAddEdit"),
    t("addLessonMembershipPackage"),
    t("addRemoveCategory"),
    t("deactivateMember"),
    t("removeMemberFromClub"),
    t("sendNotificationToMember"),
    t("bulkOperations"),
    t("exportMemberList"),
  ];

  return (
    <section className="row_am service_section about_service" id={id}>
      <div className="container">
        <div
          className="section_title"
          data-aos="fade-up"
          data-aos-duration={1500}
        >
          <h2>{t("clubMembersModuleTitle")}</h2>
          <p>{t("clubMembersModuleDescription")}</p>
        </div>

        <div className="row service_blocks">
          <div className="col-md-6 align-content-center">
            <div
              className="service_text"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <h4>
                <span>{t("clubMemberOperations")}</span>
              </h4>
              <ul className="feature_list">
                {serviceFeatures.map((feature, index) => (
                  <div
                    key={index}
                    className="feature-item-minimal animated-item"
                    style={{ animationDelay: `${index * 500}ms` }}
                  >
                    <div className="feature-icon-minimal">
                      <svg
                        className="checkmark-icon"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="checkmark-circle"
                          cx="12"
                          cy="12"
                          r="11"
                          fill="#f0f7ff"
                        />
                        <path
                          className="checkmark-check"
                          d="M7 13l3 3 7-7"
                          stroke="#4169e1"
                          strokeWidth="2"
                          fill="none"
                        />
                      </svg>
                    </div>
                    <div className="feature-text-minimal">{feature}</div>
                  </div>
                  // <li key={index}>
                  //   <div className="icon">
                  //     <span>
                  //       <i className="icofont-check" />
                  //     </span>
                  //   </div>
                  //   <div className="text">
                  //     <p>{feature}</p>
                  //   </div>
                  // </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div
              className="img video_player"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <img src={activeMembers} alt={t("clubMembersModuleTitle")} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ClubMembers;
