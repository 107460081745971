import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/whiteLogo.png";
import { useTranslation } from "react-i18next";
import "../../assets/css/clubDetailStyles.css";

function ClubDetailFooter({ club }) {
  const { t, i18n } = useTranslation();

  // Kulüp logosu varsa onu kullan, yoksa varsayılan logoyu kullan
  const logoSrc =
    club && club.clubLogo
      ? `https://cdn.pfmtennis.com/clubphotos/${club.clubLogo}`
      : logo;

  //kulüp mottosu varsa onu yazdırabiliriz
  const getClubDescription = () => {
    const currentLanguage = i18n.language;

    if (currentLanguage === "en" && club?.clubDescriptionen) {
      return club.clubDescriptionen;
    } else if (currentLanguage === "ru" && club?.clubDescriptionru) {
      return club.clubDescriptionru;
    } else {
      return (
        club?.clubDescriptiontr || t("turkeysMostComprehensiveTennisPlatform")
      );
    }
  };

  return (
    <footer className="club-detail-footer">
      <div className="container">
        <div className="footer-content">
          {/* Logo ve Açıklama */}
          <div className="footer-section">
            <div className="footer-logo">
              <Link to="/">
                {/* Ana PFM logosu */}
                <img src={logo} alt="PFM Logo" className="pfm-logo" />
              </Link>
              {/* Kulüp logosu */}
              {club && club.clubLogo && (
                <img
                  src={logoSrc}
                  alt={club.clubNametr || t("clubLogo")}
                  className="club-logo"
                />
              )}
            </div>
            <p className="footer-description">{getClubDescription()}</p>
          </div>

          {/* Hızlı Linkler */}
          <div className="footer-section">
            <h3 className="footer-title">{t("quickLinks")}</h3>
            <ul className="footer-links">
              <li>
                <Link to="/">{t("home")}</Link>
              </li>
              <li>
                <Link to="/#our_client">{t("clubs")}</Link>
              </li>
              <li>
                <a href="#club-info">{t("aboutUs")}</a>
              </li>
              <li>
                <a href="#club-contact">{t("contact")}</a>
              </li>
            </ul>
          </div>

          {/* İletişim Bilgileri */}
          <div className="footer-section">
            <h3 className="footer-title">{t("contact")}</h3>
            <ul className="footer-contact">
              {club?.clubEMail && (
                <li>
                  <i className="icofont-email"></i>
                  <a href={`mailto:${club.clubEMail}`}>{club.clubEMail}</a>
                </li>
              )}
              {club?.clubPhone && (
                <li>
                  <i className="icofont-phone"></i>
                  <a href={`tel:${club.clubPhone}`}>{club.clubPhone}</a>
                </li>
              )}
              {club?.clubAddress && (
                <li>
                  <i className="icofont-location-pin"></i>
                  <span>{club.clubAddress}</span>
                </li>
              )}
            </ul>
          </div>

          {/* Sosyal Medya */}
          <div className="footer-section">
            <h3 className="footer-title">{t("socialMedia")}</h3>
            <div className="social-links">
              <a
                href="https://www.facebook.com/people/Pfm-Tenis/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="icofont-facebook"></i>
              </a>
              <a
                href="https://www.instagram.com/pfmtennis/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="icofont-instagram"></i>
              </a>
              {club?.clubInstagram && (
                <a
                  href={`https://www.instagram.com/${club.clubInstagram}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="icofont-instagram"></i>
                </a>
              )}
              <a
                href="https://www.youtube.com/channel/UCDNkQ4idZq0Ky7Ksb2lb3yQ"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="icofont-youtube"></i>
              </a>
            </div>
          </div>
        </div>

        {/* Alt Footer */}
      </div>
      <div className="footer-bottom">
        <div className="footer-bottom-content">
          <div className="copyright">
            <p>
              © {new Date().getFullYear()} {t("pfmTennisCopyright")}
            </p>
          </div>
          {/* <div className="legal-links">
              <Link to="/privacy">{t("privacyPolicy")}</Link>
              <Link to="/terms">{t("termsOfUse")}</Link>
              <Link to="/cookies">{t("cookiesPolicy")}</Link>
            </div> */}
        </div>
      </div>
    </footer>
  );
}

export default ClubDetailFooter;
