import React, { useEffect } from "react";
import Header from "../../Components/Header/Main";
import About from "../../Components/Clubs/About";
import Whyus from "../../Components/Clubs/Whyus";
import Ourvalue from "../../Components/Clubs/Ourvalue";
import TextList from "../../Components/Clubs/TextList";
import Service from "../../Components/Clubs/Service";
import Testimonial from "../../Components/Clubs/Testimonial";
import Team from "../../Components/Clubs/Team";
import Faq from "../../Components/Clubs/Faq";
import App from "../../Components/Clubs/App";
import Footer from "../../Components/Footer/Main";
import SEO from "../../Components/SEO";
import Aos from "aos";

function Main() {
  useEffect(() => {
    Aos.init();
    Aos.refresh();
  }, []);

  return (
    <>
      <SEO
        title={{
          tr: "PFM Kulüpleri - 25+ Tenis, Padel ve Raket Sporları Kulübünde Kolay Rezervasyon",
          en: "PFM Clubs - Easy Reservation at 25+ Tennis, Padel and Racket Sports Clubs",
          ru: "Клубы PFM - Легкое бронирование в более чем 25 клубах тенниса, падела и ракеточных видов спорта",
        }}
        description={{
          tr: "PFM ile çalışan 25+ tenis, padel ve raket sporları kulübü. İstanbul Tenis Akademisi, PFM Show Court, Kavaklıdere Sosyal Kulübü ve daha fazlası. Kort rezervasyonu yapın, tenis dersleri alın ve raket sporlarını deneyimleyin.",
          en: "25+ tennis, padel and racket sports clubs working with PFM. Istanbul Tennis Academy, PFM Show Court, Kavaklıdere Social Club and more. Make court reservations, take tennis lessons and experience racket sports.",
          ru: "Более 25 клубов тенниса, падела и ракеточных видов спорта, работающих с PFM. Стамбульская теннисная академия, PFM Show Court, Социальный клуб Каваклыдере и многое другое. Бронируйте корты, берите уроки тенниса и испытайте ракеточные виды спорта.",
        }}
        keywords={{
          tr: "PFM Kulüpleri, Play for more kulüpleri, tenis kulüpleri, padel kulüpleri, raket sporları kulüpleri, kort rezervasyonu, tenis dersleri, padel dersleri, pickleball dersleri, tenis antrenörü, İstanbul Tenis Akademisi, PFM Show Court, Kavaklıdere Sosyal Kulübü, Thecourt Arsuz, Nefes Kulüp, tenis kortu, padel kortu, pickleball kortu",
          en: "PFM Clubs, Play for more clubs, tennis clubs, padel clubs, racket sports clubs, court reservation, tennis lessons, padel lessons, pickleball lessons, tennis coach, Istanbul Tennis Academy, PFM Show Court, Kavaklıdere Social Club, Thecourt Arsuz, Nefes Club, tennis court, padel court, pickleball court",
          ru: "Клубы PFM, Клубы Play for more, теннисные клубы, клубы падел, клубы ракеточных видов спорта, бронирование корта, уроки тенниса, уроки падела, уроки пикклбола, тренер по теннису, Стамбульская теннисная академия, PFM Show Court, Социальный клуб Каваклыдере, Thecourt Arsuz, Клуб Нефес, теннисный корт, корт для падела, корт для пикклбола",
        }}
        ogTitle={{
          tr: "PFM Kulüpleri - 25+ Tenis, Padel ve Raket Sporları Kulübünde Kolay Rezervasyon",
          en: "PFM Clubs - Easy Reservation at 25+ Tennis, Padel and Racket Sports Clubs",
          ru: "Клубы PFM - Легкое бронирование в более чем 25 клубах тенниса, падела и ракеточных видов спорта",
        }}
        ogDescription={{
          tr: "PFM ile çalışan 25+ tenis, padel ve raket sporları kulübü. İstanbul Tenis Akademisi, PFM Show Court, Kavaklıdere Sosyal Kulübü ve daha fazlası. Kort rezervasyonu yapın, tenis dersleri alın ve raket sporlarını deneyimleyin.",
          en: "25+ tennis, padel and racket sports clubs working with PFM. Istanbul Tennis Academy, PFM Show Court, Kavaklıdere Social Club and more. Make court reservations, take tennis lessons and experience racket sports.",
          ru: "Более 25 клубов тенниса, падела и ракеточных видов спорта, работающих с PFM. Стамбульская теннисная академия, PFM Show Court, Социальный клуб Каваклыдере и многое другое. Бронируйте корты, берите уроки тенниса и испытайте ракеточные виды спорта.",
        }}
        ogImage="https://www.playformore.app/assets/images/clubs-banner.jpg"
        ogUrl="https://www.playformore.app/clubs"
        twitterTitle={{
          tr: "PFM Kulüpleri - 25+ Tenis, Padel ve Raket Sporları Kulübünde Kolay Rezervasyon",
          en: "PFM Clubs - Easy Reservation at 25+ Tennis, Padel and Racket Sports Clubs",
          ru: "Клубы PFM - Легкое бронирование в более чем 25 клубах тенниса, падела и ракеточных видов спорта",
        }}
        twitterDescription={{
          tr: "PFM ile çalışan 25+ tenis, padel ve raket sporları kulübü. Kort rezervasyonu yapın, tenis dersleri alın ve raket sporlarını deneyimleyin.",
          en: "25+ tennis, padel and racket sports clubs working with PFM. Make court reservations, take tennis lessons and experience racket sports.",
          ru: "Более 25 клубов тенниса, падела и ракеточных видов спорта, работающих с PFM. Бронируйте корты, берите уроки тенниса и испытайте ракеточные виды спорта.",
        }}
        twitterImage="https://www.playformore.app/assets/images/clubs-banner.jpg"
      />
      <Header />
      <div className="page_wrapper">
        <About />
        <Whyus />
        <Ourvalue />
        <TextList />
        <Service />
        <Testimonial />
        <Team />
        <Faq />
        <App />
        <Footer />
      </div>
    </>
  );
}

export default Main;
