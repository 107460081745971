import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Bluedots from "../../assets/images/blue_dotes.png";
import Step1 from "../../assets/images/howstep1.png";
import Step2 from "../../assets/images/howstep2.png";
import Step3 from "../../assets/images/howstep3.png";

function HowWork() {
  const { t } = useTranslation();

  return (
    <>
      <section
        className="how_it_section white_text"
        id="how_it_section"
        itemScope
        itemType="https://schema.org/HowTo"
      >
        <div
          className="how_it_inner"
          data-aos="fade-in"
          data-aos-duration={1500}
        >
          {/* <div className="dotes_blue">
            <img src={Bluedots} alt="image" />
          </div> */}
          <div className="container">
            <div
              className="section_title"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <span className="title_badge" itemProp="name">
                {t("fastAndSimple")}
              </span>
              <h2 itemProp="headline">{t("howToUsePfm")}</h2>
            </div>
            <div
              className="row"
              itemScope
              itemType="https://schema.org/ItemList"
            >
              <div className="col-md-4">
                <div
                  className="steps_block step_border"
                  data-aos="fade-up"
                  data-aos-duration={1500}
                  itemScope
                  itemType="https://schema.org/HowToStep"
                >
                  <div className="steps">
                    <div className="icon">
                      <img
                        src={Step1}
                        alt={t("downloadAppImageAlt")}
                        loading="lazy"
                        itemProp="image"
                      />
                    </div>
                    <div className="text">
                      <h3 itemProp="name">{t("downloadApp")}</h3>
                      <ul
                        className="social"
                        itemScope
                        itemType="https://schema.org/SoftwareApplication"
                      >
                        <li>
                          <Link
                            to="https://play.google.com/store/apps/details?id=com.iskc.pfm&hl=en_US"
                            target="blank"
                            itemProp="downloadUrl"
                          >
                            <i className="icofont-brand-android-robot" />
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="https://apps.apple.com/tr/app/pfm/id1659862462?l=tr"
                            target="blank"
                            itemProp="downloadUrl"
                          >
                            <i className="icofont-brand-apple" />
                          </Link>
                        </li>
                      </ul>
                      <p itemProp="description">{t("downloadDescription")}</p>
                    </div>
                  </div>
                  <span className="step" itemProp="position">
                    01
                  </span>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="steps_block step_border"
                  data-aos="fade-up"
                  data-aos-duration={1500}
                  itemScope
                  itemType="https://schema.org/HowToStep"
                >
                  <div className="steps">
                    <div className="icon">
                      <img
                        src={Step2}
                        alt={t("createAccountImageAlt")}
                        loading="lazy"
                        itemProp="image"
                      />
                    </div>
                    <div className="text">
                      <h3 itemProp="name">{t("createAccount")}</h3>
                      <span className="tag_text" itemProp="price">
                        {t("completelyFree")}
                      </span>
                      <p itemProp="description">{t("accountDescription")}</p>
                    </div>
                  </div>
                  <span className="step" itemProp="position">
                    02
                  </span>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="steps_block"
                  data-aos="fade-up"
                  data-aos-duration={1500}
                  itemScope
                  itemType="https://schema.org/HowToStep"
                >
                  <div className="steps">
                    <div className="icon">
                      <img
                        src={Step3}
                        alt={t("enjoyPfmImageAlt")}
                        loading="lazy"
                        itemProp="image"
                      />
                    </div>
                    <div className="text">
                      <h3 itemProp="name">{t("enjoyPfm")}</h3>
                      <span className="tag_text" itemProp="offers">
                        {t("getSupport")}
                      </span>
                      <p itemProp="description">{t("enjoyDescription")}</p>
                    </div>
                  </div>
                  <span className="step" itemProp="position">
                    03
                  </span>
                </div>
              </div>
            </div>
            {/*<div className="text-center">
              <div className="btn_block">
                <Link to="/contact" className="btn puprple_btn ml-0">
                  Get started now
                </Link>
                <div className="btn_bottom" />
              </div>
            </div>*/}
          </div>
        </div>
      </section>
    </>
  );
}

export default HowWork;
