import React from "react";
import { Routes, Route } from "react-router-dom";
import Main from "../pages/Home/Main";
import Clubs from "../pages/Clubs/Main";
import ClubDetail from "../pages/Clubs/Detail";
// import Features from "../pages/Features/Main";
import About from "../pages/About/Main";
import SignIn from "../pages/Sign In/Main";
import SignUp from "../pages/Sign Up/Main";
import Contact from "../pages/Contact/Main";
import ClubPanel from "../pages/ClubPanel/Main";

const Routing = () => {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/club" element={<Clubs />} />
      <Route path="/club/:urlSlug" element={<ClubDetail />} />
      {/* <Route path="/features" element={<Features />} /> */}
      <Route path="/about" element={<About />} />
      <Route path="/club-panel" element={<ClubPanel />} />
      <Route path="/sign-in" element={<SignIn />} />
      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/contact" element={<Contact />} />
    </Routes>
  );
};

export default Routing;
