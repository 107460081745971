import React from "react";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { useTranslation } from "react-i18next";

const Textslider = {
  responsive: {
    0: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 4,
    },
  },
};

function TextList() {
  const { t } = useTranslation();

  const textItems = [
    t("nearbyClubs"),
    t("nearbyPlayers"),
    t("messaging"),
    t("reservationManagement"),
    t("leagues"),
    t("organizeGame"),
    t("nearbyCoaches"),
    t("gamePool"),
  ];

  return (
    <>
      <div
        className="text_list_section row_am"
        data-aos="fade-in"
        data-aos-duration={1500}
        itemScope
        itemType="https://schema.org/ItemList"
      >
        <div className="container">
          <span className="title_badge down_fix" itemProp="name">
            {t("whyPfm")}
          </span>
        </div>
        <div className="slider_block">
          <Swiper
            className="mySwiper"
            id="text_list_flow"
            {...Textslider}
            slidesPerView={2.1}
          >
            {textItems.map((item, index) => (
              <SwiperSlide
                key={index}
                className="item"
                itemProp="itemListElement"
                itemScope
                itemType="https://schema.org/ListItem"
              >
                <div className="text_block">
                  <span itemProp="name">{item}</span>
                  <span className="mark_star" aria-hidden="true">
                    •
                  </span>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
}

export default TextList;
