import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

const SEO = ({
  title,
  description,
  keywords,
  ogTitle,
  ogDescription,
  ogImage,
  ogUrl,
  twitterTitle,
  twitterDescription,
  twitterImage,
  schema,
  alternateUrls,
  canonicalUrl,
}) => {
  const { i18n, t } = useTranslation();

  const getAlternateUrls = () => {
    if (alternateUrls) return alternateUrls;

    const baseUrl = "https://www.playformore.app";
    const currentPath = window.location.pathname;

    return [
      { url: `${baseUrl}${currentPath}`, hreflang: "tr" },
      { url: `${baseUrl}/en${currentPath}`, hreflang: "en" },
      { url: `${baseUrl}/ru${currentPath}`, hreflang: "ru" },
      { url: `${baseUrl}${currentPath}`, hreflang: "x-default" },
    ];
  };

  const getLocalizedContent = (content) => {
    if (typeof content === "object") {
      return content[i18n.language] || content.tr || content;
    }
    return content;
  };

  const localizedTitle = getLocalizedContent(title);
  const localizedDescription = getLocalizedContent(description);
  const localizedKeywords = getLocalizedContent(keywords);
  const localizedOgTitle = getLocalizedContent(ogTitle || title);
  const localizedOgDescription = getLocalizedContent(
    ogDescription || description
  );
  const localizedTwitterTitle = getLocalizedContent(twitterTitle || title);
  const localizedTwitterDescription = getLocalizedContent(
    twitterDescription || description
  );

  return (
    <Helmet>
      {/* Temel Meta Etiketleri */}
      <title>{localizedTitle}</title>
      <meta name="description" content={localizedDescription} />
      <meta name="keywords" content={localizedKeywords} />
      <meta name="language" content={i18n.language} />
      <meta name="robots" content="index, follow" />
      <meta name="author" content="Play For More" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />

      {/* Open Graph Meta Etiketleri */}
      <meta property="og:title" content={localizedOgTitle} />
      <meta property="og:description" content={localizedOgDescription} />
      <meta property="og:image" content={ogImage} />
      <meta property="og:url" content={ogUrl || window.location.href} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content={i18n.language} />
      <meta property="og:site_name" content="Play For More" />

      {/* Twitter Card Meta Etiketleri */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={localizedTwitterTitle} />
      <meta name="twitter:description" content={localizedTwitterDescription} />
      <meta name="twitter:image" content={twitterImage || ogImage} />

      {/* Dil Alternatifleri */}
      {getAlternateUrls().map(({ url, hreflang }) => (
        <link key={hreflang} rel="alternate" href={url} hreflang={hreflang} />
      ))}

      {/* Canonical URL */}
      <link rel="canonical" href={canonicalUrl || window.location.href} />

      {/* Schema.org İşaretlemesi */}
      {schema && (
        <script type="application/ld+json">
          {JSON.stringify(getLocalizedContent(schema))}
        </script>
      )}
    </Helmet>
  );
};

export default SEO;
