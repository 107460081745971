import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/whiteLogo.png";
import { useTranslation } from "react-i18next";

function Main() {
  const { t } = useTranslation();
  return (
    <>
      <footer
        id="contact_section"
        className="white_text"
        data-aos="fade-in"
        data-aos-duration={1500}
        itemScope
        itemType="https://schema.org/WPFooter"
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div
                className="logo_side"
                itemScope
                itemType="https://schema.org/Organization"
              >
                <div className="logo">
                  <Link to="/" itemProp="url">
                    <img
                      src={logo}
                      alt={t("pfmLogo")}
                      loading="lazy"
                      itemProp="logo"
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6 text-md-end">
              <div className="contact_social_wrapper">
                <ul
                  className="contact_info"
                  itemScope
                  itemType="https://schema.org/ContactPoint"
                >
                  <li>
                    <Link to="mailto:info@playformore.app" itemProp="email">
                      info@playformore.app
                    </Link>
                  </li>
                </ul>
                <ul
                  className="social_media"
                  itemScope
                  itemType="https://schema.org/ItemList"
                >
                  <li
                    itemProp="itemListElement"
                    itemScope
                    itemType="https://schema.org/ListItem"
                  >
                    <Link
                      to="https://www.facebook.com/people/Pfm-Tenis/"
                      target="blank"
                      itemProp="url"
                      aria-label={t("facebookPage")}
                    >
                      <i className="icofont-facebook" />
                    </Link>
                  </li>
                  <li
                    itemProp="itemListElement"
                    itemScope
                    itemType="https://schema.org/ListItem"
                  >
                    <Link
                      to="https://www.instagram.com/pfmtennis/"
                      target="blank"
                      itemProp="url"
                      aria-label={t("instagramPage")}
                    >
                      <i className="icofont-instagram" />
                    </Link>
                  </li>
                  <li
                    itemProp="itemListElement"
                    itemScope
                    itemType="https://schema.org/ListItem"
                  >
                    <Link
                      to="https://www.youtube.com/channel/UCDNkQ4idZq0Ky7Ksb2lb3yQ"
                      target="blank"
                      itemProp="url"
                      aria-label={t("youtubeChannel")}
                    >
                      <i className="icofont-youtube" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer_bottom">
          <div className="container">
            <div className="ft_inner">
              <div className="copy_text text-center w-100">
                <p itemProp="copyrightYear">{t("copyright")}</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Main;
