import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
// İkon importları
import { TbWorldCheck } from "react-icons/tb";
import { FaUserPlus } from "react-icons/fa";
import { MdSentimentSatisfied } from "react-icons/md";

function HowWork() {
  const { t } = useTranslation();

  return (
    <>
      <section className="how_it_section white_text" id="how_it_section">
        <div
          className="how_it_inner"
          data-aos="fade-in"
          data-aos-duration={1500}
        >
          {/* <div className="dotes_blue">
            <img src={Bluedots} alt="image" />
          </div> */}
          <div className="container">
            <div
              className="section_title"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <span className="title_badge">{t("fastAndSimple")}</span>
              <h2>Kulüp Paneli Nasıl Kullanabilirim</h2>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div
                  className="steps_block step_border"
                  data-aos="fade-up"
                  data-aos-duration={1500}
                >
                  <div className="steps">
                    <div className="icon">
                      <TbWorldCheck size={50} />
                    </div>
                    <div className="text">
                      <h3>{t("downloadApp")}</h3>
                      {/* <ul className="social">
                        <li>
                          <Link
                            to="https://play.google.com/store/apps/details?id=com.iskc.pfm&hl=en_US"
                            target="blank"
                          >
                            <i className="icofont-brand-android-robot" />
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="https://apps.apple.com/tr/app/pfm/id1659862462?l=tr"
                            target="blank"
                          >
                            <i className="icofont-brand-apple" />
                          </Link>
                        </li>
                      </ul> */}


                      <p>playformore.app Adresine git ve yeni bir kulüp kaydı oluştur</p>
                      <p>{t("downloadDescription")}</p>
                    </div>
                  </div>
                  <span className="step">01</span>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="steps_block step_border"
                  data-aos="fade-up"
                  data-aos-duration={1500}
                >
                  <div className="steps">
                    <div className="icon">
                      <FaUserPlus size={50} />
                    </div>
                    <div className="text">
                      <h3>{t("createAccount")}</h3>
                      <span className="tag_text">{t("completelyFree")}</span>
                      <p>Kulüp bilgilerini, iletişim, konum ve diğer gerekli bilgilerini doldur</p>
                    </div>
                  </div>
                  <span className="step">02</span>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="steps_block"
                  data-aos="fade-up"
                  data-aos-duration={1500}
                >
                  <div className="steps">
                    <div className="icon">
                      <MdSentimentSatisfied size={50} />
                    </div>
                    <div className="text">
                      <h3>Kulübünü Yönet</h3>
                      <span className="tag_text">{t("getSupport")}</span>
                      <p>Kulübünün tüm ihtiyaçlarını karşılayan bu panelin keyfini çıkar</p>
                    </div>
                  </div>
                  <span className="step">03</span>
                </div>
              </div>
            </div>
            {/* <div className="text-center">
              <div className="btn_block">
                <Link to="/contact" className="btn puprple_btn ml-0">
                  Get started now
                </Link>
                <div className="btn_bottom" />
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
}

export default HowWork;
