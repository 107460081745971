import React, { useEffect } from "react";
import Header from "../../Components/Header/Main";
import Contactblock from "../../Components/Contact/Contactblock";
import Contactform from "../../Components/Contact/Contactform";
import Map from "../../Components/Contact/Map";
import Footer from "../../Components/Footer/Main";
import SEO from "../../Components/SEO";
import Aos from "aos";

function Main() {
  useEffect(() => {
    Aos.init();
    Aos.refresh();
  }, []);

  return (
    <>
      <SEO
        title={{
          tr: "PFM İletişim - Tenis ve Raket Sporları Kulüp Yönetim Sistemi İle Bağlantı Kurun",
          en: "PFM Contact - Connect With Tennis and Racket Sports Club Management System",
          ru: "Связаться с PFM - Система управления клубом для тенниса и ракеточных видов спорта",
        }}
        description={{
          tr: "PFM ile iletişime geçin. Tenis, padel ve raket sporları kulüpleri için profesyonel yönetim sistemi yazılımımız hakkında bilgi alın, demo talep edin veya sorularınızı sorun.",
          en: "Contact PFM. Get information about our professional management system software for tennis, padel and racket sports clubs, request a demo or ask your questions.",
          ru: "Свяжитесь с PFM. Получите информацию о нашем профессиональном программном обеспечении системы управления для клубов тенниса, падела и ракеточных видов спорта, запросите демонстрацию или задайте вопросы.",
        }}
        keywords={{
          tr: "PFM İletişim, Play for more iletişim, Tenis Kulüp Yönetim Sistemi iletişim, Raket Sporları Yazılımı iletişim, PFM demo, Kulüp Yönetim Yazılımı demo, PFM destek, Kort Rezervasyon Sistemi destek",
          en: "PFM Contact, Play for more contact, Tennis Club Management System contact, Racket Sports Software contact, PFM demo, Club Management Software demo, PFM support, Court Reservation System support",
          ru: "Связаться с PFM, Связаться с Play for more, Связаться с системой управления теннисным клубом, Связаться с программным обеспечением для ракеточных видов спорта, Демонстрация PFM, Демонстрация программного обеспечения для управления клубом, Поддержка PFM, Поддержка системы бронирования кортов",
        }}
        ogTitle={{
          tr: "PFM İletişim - Tenis ve Raket Sporları Kulüp Yönetim Sistemi İle Bağlantı Kurun",
          en: "PFM Contact - Connect With Tennis and Racket Sports Club Management System",
          ru: "Связаться с PFM - Система управления клубом для тенниса и ракеточных видов спорта",
        }}
        ogDescription={{
          tr: "PFM ile iletişime geçin. Tenis, padel ve raket sporları kulüpleri için profesyonel yönetim sistemi yazılımımız hakkında bilgi alın.",
          en: "Contact PFM. Get information about our professional management system software for tennis, padel and racket sports clubs.",
          ru: "Свяжитесь с PFM. Получите информацию о нашем профессиональном программном обеспечении системы управления для клубов тенниса, падела и ракеточных видов спорта.",
        }}
        ogImage="https://www.playformore.app/assets/images/contact-banner.jpg"
        ogUrl="https://www.playformore.app/contact"
      />
      <Header />
      <div className="page_wrapper">
        <Contactblock />
        <Contactform />
        <Map />
        <Footer />
      </div>
    </>
  );
}

export default Main;
