import React, { useState, useEffect } from "react";
import { FaMapMarkerAlt, FaInstagram, FaGlobe } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

const Hero = ({ club }) => {
  const { t, i18n } = useTranslation();
  const [loaded, setLoaded] = useState(false);
  console.log(club);

  const getClubName = () => {
    return club[`clubName${i18n.language}`] || club.clubNametr;
  };

  const getClubDesc = () => {
    return club[`clubDesc${i18n.language}`] || club.clubDesctr;
  };

  const createMarkup = (htmlContent) => {
    return { __html: htmlContent };
  };

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <section
      className="club-hero"
      itemScope
      itemType="https://schema.org/SportsActivityLocation"
    >
      <div className="club-hero__background">
        <div
          className="club-hero__background-image"
          style={{
            backgroundImage: `url(https://cdn.pfmtennis.com/clubphotos/${club.clubPhotos[1]?.photoUrl})`,
          }}
          aria-hidden="true"
          itemProp="image"
        >
          <div className="club-hero__overlay"></div>
        </div>
      </div>
      <div className="container">
        <motion.div
          className="club-hero__content"
          initial={{ opacity: 0, y: 20 }}
          animate={loaded ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.6 }}
        >
          {/* <motion.div
            className="club-hero__logo"
            whileHover={{ scale: 1.03 }}
            transition={{ type: "spring", stiffness: 300 }}
            itemProp="logo"
          >
            <img
              src={`https://cdn.pfmtennis.com/clubphotos/${club.clubLogo}`}
              alt={getClubName()}
              className="club-hero__logo-image"
              loading="eager"
              width="200"
              height="200"
            />
          </motion.div> */}

          <div className="club-hero__info">
            <motion.h1
              className="club-hero__title"
              initial={{ opacity: 0, y: 10 }}
              animate={loaded ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 0.5, delay: 0.2 }}
              itemProp="name"
            >
              {getClubName()}
            </motion.h1>

            {/* <motion.div 
              className="club-hero__description"
              initial={{ opacity: 0, y: 10 }}
              animate={loaded ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 0.5, delay: 0.3 }}
              dangerouslySetInnerHTML={createMarkup(getClubDesc())}
            /> */}

            <motion.div
              className="club-hero__location"
              initial={{ opacity: 0, y: 10 }}
              animate={loaded ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 0.5, delay: 0.4 }}
              itemProp="address"
              itemScope
              itemType="https://schema.org/PostalAddress"
            >
              <FaMapMarkerAlt className="club-hero__location-icon" />
              <span itemProp="addressLocality">{club.clubCity}</span>
            </motion.div>

            <motion.div
              className="club-hero__contact"
              initial={{ opacity: 0, y: 10 }}
              animate={loaded ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 0.5, delay: 0.5 }}
              itemProp="contactPoint"
              itemScope
              itemType="https://schema.org/ContactPoint"
            >
              {club.clubInstagram && (
                <a
                  href={`https://instagram.com/${club.clubInstagram}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="club-hero__contact-item"
                  aria-label={`${t("visitOurInstagram")}`}
                  itemProp="sameAs"
                >
                  <FaInstagram className="club-hero__contact-icon" />
                  <span>Instagram</span>
                </a>
              )}

              {club.clubWebSite && (
                <a
                  href={club.clubWebSite}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="club-hero__contact-item"
                  aria-label={`${t("visitOurWebsite")}`}
                  itemProp="url"
                >
                  <FaGlobe className="club-hero__contact-icon" />
                  <span>Web Site</span>
                </a>
              )}
            </motion.div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default Hero;
