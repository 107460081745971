import React from "react";
import ContolPanel from "../../assets/images/club-panel/controlPanel2.png";
import { IoCalendarClearOutline } from "react-icons/io5";
import { MdOutlineAddBox } from "react-icons/md";
import { BiTask } from "react-icons/bi";
import { TbCalendarRepeat } from "react-icons/tb";
import { AiOutlineSchedule } from "react-icons/ai";
import { useTranslation } from "react-i18next";

function ControlPanel({ id }) {
  const { t } = useTranslation();
  return (
    <>
      <section className="why_choose" id={id}>
        <div className="container">
          <div
            className="section_title"
            data-aos="fade-up"
            data-aos-duration={1500}
          >
            <h2>{t("advancedControlPanelTitle")}</h2>
            <p>{t("advancedControlPanelDescription2")}</p>
          </div>

          <div className="content_wrapper">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div
                  className="laptop-content"
                  data-aos="fade-right"
                  data-aos-duration={1500}
                >
                  <img
                    src={ContolPanel}
                    alt="Laptop görseli"
                    className="img-fluid cursor-pointer"
                    data-bs-toggle="modal"
                    data-bs-target="#imageModal"
                  />
                </div>
              </div>

              <div className="col-lg-6">
                <div
                  className="content-side"
                  data-aos="fade-left"
                  data-aos-duration={1500}
                >
                  {/* <h3>İşletmeniz için Dijital Çözümler</h3> */}
                  {/* <p>
                  Takvimde herhangi bir boş hücreyi seçerek açılan işlem
                  kutusundan yapmak istediğiniz işlemi seçip işleminize devam
                  edebilirsiniz.
                </p> */}

                  <div className="features">
                    <div className="feature-item">
                      <div className="feature-header">
                        <IoCalendarClearOutline className="feature-icon" />
                        <h4>{t("courtCloseOpenOperation")}</h4>
                      </div>
                    </div>

                    <div className="feature-item">
                      <div className="feature-header">
                        <MdOutlineAddBox className="feature-icon" />
                        <h4>{t("newReservation")}</h4>
                      </div>
                    </div>

                    <div className="feature-item">
                      <div className="feature-header">
                        <BiTask className="feature-icon" />
                        <h4>{t("createNewLesson")}</h4>
                      </div>
                    </div>

                    <div className="feature-item">
                      <div className="feature-header">
                        <TbCalendarRepeat className="feature-icon" />
                        <h4>{t("periodicReservation")}</h4>
                      </div>
                    </div>

                    <div className="feature-item">
                      <div className="feature-header">
                        <AiOutlineSchedule className="feature-icon" />
                        <h4>{t("detailedCourtClosingScreen")}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="modal fade"
        id="imageModal"
        tabIndex="-1"
        aria-labelledby="imageModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content bg-transparent border-0">
            <div className="modal-header border-0 p-2">
              <button
                type="button"
                className="btn-close bg-white rounded-circle"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body p-0 text-center">
              <img
                src={ContolPanel}
                alt="Laptop görseli"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ControlPanel;
