import React from "react";
import { useTranslation } from "react-i18next";
import defaultFgImage from "../../assets/images/club-panel/controlPanel2.png";

function ClubPanelBanner() {
  const { t, i18n } = useTranslation();

  return (
    <section
      className="club_panel_banner"
      itemScope
      itemType="https://schema.org/WPHeader"
      lang={i18n.language}
    >
      <div className="banner_overlay">
        <div className="container">
          <div
            className="section_title"
            data-aos="fade-up"
            data-aos-duration={1500}
            itemScope
            itemType="https://schema.org/WebPageElement"
          >
            <h1 className="title_badge" itemProp="name">
              {t("clubManagementPanel")}
            </h1>
          </div>
          <div
            className="banner_content"
            data-aos="fade-up"
            data-aos-duration={1500}
            itemScope
            itemType="https://schema.org/WebPageElement"
          >
            {/* <span className="title_badge">{t("clubManagementPanel")}</span> */}
            <h2 itemProp="headline">
              <span itemProp="text" lang={i18n.language}>
                {t("allOperationsFromOneScreen")}
              </span>
            </h2>
            <div
              className="container"
              data-aos="fade-up"
              data-aos-duration={1800}
              itemScope
              itemType="https://schema.org/ImageObject"
            >
              {/* <div className="banner_foreground_image">
                <img
                  src={defaultFgImage}
                  alt={t("clubPanelPreview")}
                  loading="lazy"
                  itemProp="contentUrl"
                  width="100%"
                  height="auto"
                  aria-label={t("clubPanelPreview")}
                />
              </div> */}
            </div>
            {/* <p itemProp="description" lang={i18n.language}>
              {t("pfmClubPanelDescription")}
            </p> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default ClubPanelBanner;
