import React from "react";
import teamManagers from "../../assets/images/club-panel/teamManagers.png";
import clubCoaches from "../../assets/images/club-panel/clubCoaches.png";
import clubPhysioterapists from "../../assets/images/club-panel/teamPhysioterapists.png";
import { useTranslation } from "react-i18next";

function Team({ id }) {
  const { t } = useTranslation();
  return (
    <section className="row_am service_section about_service" id={id}>
      <div className="container">
        <div
          className="section_title"
          data-aos="fade-up"
          data-aos-duration={1500}
        >
          <h2>{t("teamModule")}</h2>
          <p>{t("teamModuleDescription2")}</p>
        </div>

        {/* Ekip Üyeleri Bölümü */}
        <div className="team-members-section mt-5">
          <div className="row mb-4">
            <div
              className="col-md-6 mb-4"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <div className="team-card">
                <div className="team-image">
                  <img
                    src={teamManagers}
                    alt="Yöneticiler"
                    className="img-fluid"
                  />
                </div>
                <div className="team-title">
                  <h4>{t("managers")}</h4>
                  <p>{t("managersDescription")}</p>
                </div>
              </div>
            </div>

            <div
              className="col-md-6 mb-4"
              data-aos="fade-up"
              data-aos-duration={1500}
              data-aos-delay={200}
            >
              <div className="team-card">
                <div className="team-image">
                  <img
                    src={clubCoaches}
                    alt="Antrenörler"
                    className="img-fluid"
                  />
                </div>
                <div className="team-title">
                  <h4>{t("coaches")}</h4>
                  <p>{t("coachesDescription")}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div
              className="col-md-6 mx-auto mb-4"
              data-aos="fade-up"
              data-aos-duration={1500}
              data-aos-delay={400}
            >
              <div className="team-card">
                <div className="team-image">
                  <img
                    src={clubPhysioterapists}
                    alt="Fizyoterapistler"
                    className="img-fluid"
                  />
                </div>
                <div className="team-title">
                  <h4>{t("physiotherapists")}</h4>
                  <p>{t("physiotherapistsDescription")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Team;
