import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const Gallery = ({ club }) => {
  const { t } = useTranslation();
  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Modal açıldığında body scroll'unu engelle
  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isModalOpen]);

  const handleImageClick = (photo) => {
    setSelectedImage(photo);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
    setIsModalOpen(false);
  };

 
  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === "Escape") {
        handleCloseModal();
      }
    };

    if (isModalOpen) {
      window.addEventListener("keydown", handleEscKey);
    }

    return () => {
      window.removeEventListener("keydown", handleEscKey);
    };
  }, [isModalOpen]);

  return (
    <section
      className="club-gallery"
      itemScope
      itemType="https://schema.org/ImageGallery"
    >
      <div className="container">
        <h2 className="club-gallery__title" itemProp="name">
          {t("gallery")}
        </h2>
        <div className="club-gallery__grid">
          {club.clubPhotos.map((photo, index) => (
            <div
              key={index}
              className="club-gallery__item"
              onClick={() => handleImageClick(photo)}
              itemProp="image"
              itemScope
              itemType="https://schema.org/ImageObject"
            >
              <img
                src={`https://cdn.pfmtennis.com/clubphotos/${photo.photoUrl}`}
                alt={`${club.clubName} - ${t("gallery")} ${index + 1}`}
                className="club-gallery__image"
                itemProp="contentUrl"
                loading="lazy"
              />
              <div className="club-gallery__overlay">
                <span className="club-gallery__zoom">{t("enlarge")}</span>
              </div>
            </div>
          ))}
        </div>

        {isModalOpen && (
          <div className="club-gallery__modal" onClick={handleCloseModal}>
            <div
              className="club-gallery__modal-content"
              onClick={(e) => e.stopPropagation()}
            >
              <button
                className="club-gallery__modal-close"
                onClick={handleCloseModal}
                aria-label={t("close")}
              >
                <FaTimes />
              </button>
              <div className="club-gallery__modal-image-container">
                <img
                  src={`https://cdn.pfmtennis.com/clubphotos/${selectedImage.photoUrl}`}
                  alt={`${club.clubName} - ${t("largeImage")}`}
                  className="club-gallery__modal-image"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Gallery;
