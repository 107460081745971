import React from "react";
import { useTranslation } from "react-i18next";
import activeMembers from "../../assets/images/club-panel/activeMembers.png";
import createCourse1 from "../../assets/images/club-panel/createCourse1.png";
import createCourse2 from "../../assets/images/club-panel/createCourse2.png";
import createCourse3 from "../../assets/images/club-panel/createCourse3.png";

function Academy({ id }) {
  const { t } = useTranslation();

  const serviceFeatures = [
    t("createNewCourse"),
    t("createCourseGroups"),
    t("detailedViewInCourseList"),
    t("attendanceByClassHours"),
    t("addRemoveStudentsToCourse"),
    t("coursePaymentControl"),
    t("exportCourseList"),
  ];

  return (
    <section className="row_am service_section about_service" id={id}>
      <div className="container">
        <div
          className="section_title"
          data-aos="fade-up"
          data-aos-duration={1500}
        >
          <h2>{t("academyModule")}</h2>
          <p>{t("moduleDescription")}</p>
        </div>

        <div className="row service_blocks">
          <div className="col-md-6">
            <div
              className="img video_player"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <img src={activeMembers} alt="image" />
            </div>
          </div>
          <div className="col-md-6 align-content-center">
            <div
              className="service_text"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <h4>
                <span>{t("operationsInAcademyModule")}</span>
              </h4>
              <ul className="feature_list">
                {serviceFeatures.map((feature, index) => (
                  <div
                    key={index}
                    className="feature-item-minimal animated-item"
                    style={{ animationDelay: `${index * 500}ms` }}
                  >
                    <div className="feature-icon-minimal">
                      <svg
                        className="checkmark-icon"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="checkmark-circle"
                          cx="12"
                          cy="12"
                          r="11"
                          fill="#f0f7ff"
                        />
                        <path
                          className="checkmark-check"
                          d="M7 13l3 3 7-7"
                          stroke="#4169e1"
                          strokeWidth="2"
                          fill="none"
                        />
                      </svg>
                    </div>
                    <div className="feature-text-minimal">{feature}</div>
                  </div>
                  // <li key={index}>
                  //   <div className="icon">
                  //     <span>
                  //       <i className="icofont-check" />
                  //     </span>
                  //   </div>
                  //   <div className="text">
                  //     <p>{feature}</p>
                  //   </div>
                  // </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="lesson-creation-steps">
          <div
            className="section_title"
            data-aos="fade-up"
            data-aos-duration={1500}
          >
            <h2>{t("createCourseIn3Steps")}</h2>
            <p>{t("createCourseDescription")}</p>
          </div>

          <div className="row">
            <div
              className="col-md-4 mb-4"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <div className="step-card">
                <div className="step-image">
                  <img
                    src={createCourse1}
                    alt={t("step1")}
                    className="img-fluid"
                  />
                  <div className="step-overlay">
                    <div className="step-content">
                      <h3>{t("step1")}</h3>
                      <p>{t("enterCourseInfo")}</p>
                    </div>
                  </div>
                </div>
                <div className="step-title">
                  <h4>{t("courseInfo")}</h4>
                </div>
              </div>
            </div>

            <div
              className="col-md-4 mb-4"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <div className="step-card">
                <div className="step-image">
                  <img
                    src={createCourse2}
                    alt={t("step2")}
                    className="img-fluid"
                  />
                  <div className="step-overlay">
                    <div className="step-content">
                      <h3>{t("step2")}</h3>
                      <p>{t("determineCourseSchedule")}</p>
                    </div>
                  </div>
                </div>
                <div className="step-title">
                  <h4>{t("scheduleSettings")}</h4>
                </div>
              </div>
            </div>

            <div
              className="col-md-4 mb-4"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <div className="step-card">
                <div className="step-image">
                  <img
                    src={createCourse3}
                    alt={t("step3")}
                    className="img-fluid"
                  />
                  <div className="step-overlay">
                    <div className="step-content">
                      <h3>{t("step3")}</h3>
                      <p>{t("addStudents")}</p>
                    </div>
                  </div>
                </div>
                <div className="step-title">
                  <h4>{t("studentAddition")}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Academy;
